import { Box, Button, Center, Checkbox, Flex, HStack, Icon, Input, ListItem, Tag, Text, Tooltip, UnorderedList, useToast } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import CModal from '../CModal/Index';
import { MdHelp } from 'react-icons/md'
import Api from '../../api/Api';
import { importacao, importacaoValidado } from '../../types/ImportacaoTypes';
import moment from 'moment';
import { Verbose } from '../../service/Verbose';
import CurrencyInput from 'react-currency-input-field';

type ModalConfirmarImportacao = {
    open: boolean
    onClose: () => any
    onConfirm: () => any
    list: importacaoValidado
}

const ModalConfirmarDados: React.FC<ModalConfirmarImportacao> = (prop) => {
    const toast = useToast();
    const [load, setLoad] = useState<boolean>(false);
    const [dataEnvio, setDataEnvio] = useState<string>(moment().format('YYYY-MM-DD'));
    const [diaUtil, setDiaUtil] = useState<boolean>(false);
    // const [liquidoEmpresa, setLiquidoEmpresa] = useState<string>("");
    // const [PVL, setPVL] = useState<number>(0)
    const [confirm, setConfirm] = useState<boolean>(false)
    const PVLRef = useRef<HTMLInputElement>(null);
    const LiquidoEmpresaRef = useRef<HTMLInputElement>(null);

    const column: TableColumn<importacao>[] = [
        {
            name: "SubConta",
            selector: (row) => row.subconta,
            compact: true,
            sortable: true,
        },
        {
            name: "Subtitular",
            selector: (row) => row.subtitular,
            compact: true,
            sortable: true,
        },
        {
            name: "Ativo",
            compact: true,
            selector: (row) => row.ativo,
            sortable: true,
        },
        {
            name: "Resultado (%)",
            selector: (row) => row.resultadoPorcentagem,
            compact: true,
            sortable: true,
        },
        {
            name: "Resultado",
            compact: true,
            selector: (row) => row.resultado,
            sortable: true,
        },
        {
            name: "Qtd Liquida",
            compact: true,
            selector: (row) => row.qtdLiquida,
            sortable: true,
        },
        {
            compact: true,
            name: "Qtd Compra",
            selector: (row) => row.qtdCompra,
            sortable: true,
        },
        {
            name: "Qtd Venda",
            selector: (row) => row.qtdVenda,
            compact: true,
            sortable: true,
        },
        {
            name: "Compra Média",
            compact: true,
            selector: (row) => row.compraMedia,
            sortable: true,
        },
        {
            name: "Venda Média",
            compact: true,
            selector: (row) => row.vendaMedia,
            sortable: true,
        },
        {
            name: "Total compra",
            compact: true,
            selector: (row) => row.totalCompra,
            sortable: true,
        },
        {
            name: "Total Venda",
            compact: true,
            selector: (row) => row.totalVenda,
            sortable: true,
        },
        {
            name: "Custos",
            compact: true,
            selector: (row) => row.custos,
            sortable: true,
        },
    ]

    function confirmarDados() {
        const removeStatus: importacao[] = prop.list.lista!
        
        const data = {
            data: dataEnvio,
            lista: removeStatus,
            messageStatus: prop.list.messageStatus,
            status: prop.list.status,
            ultimoDia: diaUtil,
            liquidoEmpresa: LiquidoEmpresaRef.current?.value.replace("R$ ", ""),
            totalPVL: PVLRef.current?.value
        }
        // setLoad(true)
        Verbose({ "/ImportacaoCsv/ConfirmaImportacaoCsv(request)": data });
        if (PVLRef.current?.value && parseFloat(PVLRef.current?.value) >= 0) {
            if (dataEnvio !== "" && dataEnvio.split("-").length === 3 && LiquidoEmpresaRef.current?.value !== null && PVLRef.current?.value !== null) {
                Api.post("/ImportacaoCsv/ConfirmaImportacaoCsv", data)
                    .then(() => {
                        prop.onConfirm();
                        setLoad(false);
                        setConfirm(false)
                        toast({
                            title: "Importação realizada com sucesso",
                            status: "success",
                            duration: 5000,
                            isClosable: true
                        })
                    })
                    .catch(err => {
                        setLoad(false)
                        Verbose({ "/ImportacaoCsv/ConfirmaImportacaoCsv(catch)": err.response });
                        setConfirm(false)
                        toast({
                            title: "Erro no registro dos itens",
                            duration: 4000,
                            status: "error",
                            isClosable: true
                        })
                    })
            } else {
                setLoad(false)
                toast({
                    title: "Preenche a data de envio",
                    duration: 4000,
                    status: "error",
                    isClosable: true
                })
            }
        } else {
            setLoad(false)
            toast({
                title: "PVL não é permitido números negativos",
                status: "error",
                duration: 5000,
                isClosable: true
            })
        }

    }

    return (
        <>
            <CModal
                open={prop.open}
                size="6xl"
                onClose={prop.onClose}
                title="Arquivo analisado / Dados a serem importados"
                footer={
                    <HStack spacing={4}>
                        <Button onClick={prop.onClose}>
                            Voltar
                        </Button>
                        <Button
                            onClick={() => setConfirm(true)}
                            variant="primary"
                        >
                            Importar dados
                        </Button>
                    </HStack>
                }
            >
                <Box>
                    <Flex justifyContent="space-between" alignItems="center">
                        <HStack>

                            <Box w="280px" pb="5">
                                <Text>Data de envio</Text>
                                <Input
                                    value={dataEnvio}
                                    onChange={(e) => setDataEnvio(e.target.value)}
                                    type="date"
                                />
                            </Box>

                            <Box w="280px" pb="5">
                                <Text>Líquido Empresa</Text>
                                <Input
                                    type="text"
                                    ref={LiquidoEmpresaRef}
                                    as={CurrencyInput}
                                    defaultValue={0}
                                    decimalSeparator=","
                                    groupSeparator='.'
                                    prefix='R$'
                                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                />
                            </Box>


                            <Box w="280px" pb="5">
                                <Text>PVL</Text>
                                <Input
                                    type="number"
                                    min={0}
                                    ref={PVLRef}
                                />
                            </Box>

                        </HStack>

                        <HStack spacing={2} alignItems="center">
                            {
                                prop.list.status === 'error' && (
                                    <Tooltip label={prop.list.messageStatus}>
                                        <Box display="flex" alignItems="center">
                                            <Icon as={MdHelp} color="primary.100" w="20px" h="20px" />
                                        </Box>
                                    </Tooltip>
                                )
                            }

                            <Box>
                                <Tag colorScheme={prop.list.status === "success" ? "green" : "primary"}
                                    color={prop.list.status === "success" ? "black" : "white.100"}>
                                    {prop.list.status === 'success' && "Analisados com sucesso!"}
                                    {prop.list.status === 'error' && "Erro na leitura do arquivo!"}
                                </Tag>
                            </Box>
                        </HStack>
                    </Flex>
                    <Checkbox
                        size="lg"
                        isChecked={diaUtil}
                        onChange={(e) => setDiaUtil(e.target.checked)}
                    >
                        É o último dia útil
                    </Checkbox>

                    <Box h="480px">
                        <Box overflowX="scroll" h="480px">
                            <DataTable
                                columns={column}
                                data={prop.list.lista ? prop.list.lista : []}
                                fixedHeader
                                customStyles={{
                                    responsiveWrapper: {
                                        style: {
                                            overflow: "initial"
                                        }
                                    }
                                }}
                                noDataComponent={
                                    <Center p="8" flexDirection="column">
                                        <Box>
                                            <Text pb="2" fontSize="20px">Nenhum dado foi encontrado. Isso pode acontecer por conta de:</Text>
                                            <UnorderedList>
                                                <ListItem>Colunas inválidas.</ListItem>
                                                <ListItem>Nenhum item em seu arquivo csv foi identificado.</ListItem>
                                            </UnorderedList>
                                        </Box>
                                    </Center>
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            </CModal>
            <CModal
                open={confirm}
                onClose={() => setConfirm(false)}
                title="Você tem certeza disso?"
                footer={
                    <HStack spacing={4}>
                        <Button variant="primary-outline" onClick={() => setConfirm(false)}>
                            Voltar
                        </Button>
                        <Button variant="primary" isLoading={load} onClick={() => confirmarDados()}>
                            importar mesmo assim
                        </Button>
                    </HStack>
                }
            >
                <Text>
                    Antes de salvar avalie todos os campos e os dados desse arquivo, verifique caso você queira importar informações em que nesse dia, mês ano já esteja ocupado, porque isso pode causar diversos erros de cálculos e comprometerá outros dados importantes. Deseja mesmo importar esses dados?
                </Text>
            </CModal>
        </>
    );
}

export default ModalConfirmarDados;