import { Button, Divider, FormControl, FormLabel, Heading, HStack, Input, Stack, useToast } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Api from '../../api/Api';
import { Verbose } from '../../service/Verbose';
import InputMask from "react-input-mask";
import { UsuarioPerfil } from '../../types/UsuarioTypes';
type condicionalUserType = {
    isAdministrador: false
    idUsuario?: string | null | undefined
} | {
    isAdministrador: true
    idUsuario: string | null | undefined
}

type PerfilFormType = condicionalUserType & {
    editavel: boolean
}

const PerfilForm: React.FC<PerfilFormType> = (prop) => {
    const nomeRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const cpfRef = useRef<HTMLInputElement>(null);
    const dataNascimentoRef = useRef<HTMLInputElement>(null);
    const telefoneRef = useRef<HTMLInputElement>(null);
    const logradouroRef = useRef<HTMLInputElement>(null);
    const numeroRef = useRef<HTMLInputElement>(null);
    const complementoRef = useRef<HTMLInputElement>(null);
    const bairroRef = useRef<HTMLInputElement>(null);
    const cidadeRef = useRef<HTMLInputElement>(null);
    const [cep, setCep] = useState("");
    const [uf, setUf] = useState("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(false);
    const toast = useToast();

    function setDados(data: UsuarioPerfil) {
        if (nomeRef.current) nomeRef.current.value = data.nome;
        if (emailRef.current) emailRef.current.value = data.email;
        if (cpfRef.current) cpfRef.current.value = data.cpf;
        if (dataNascimentoRef.current)
            dataNascimentoRef.current.value = data.dataNascimento;
        if (telefoneRef.current) telefoneRef.current.value = data.telefone;
        if (logradouroRef.current)
            logradouroRef.current.value = data.logradouro;
        if (numeroRef.current) numeroRef.current.value = data.numero;
        if (complementoRef.current)
            complementoRef.current.value = data.complemento;
        if (bairroRef.current) bairroRef.current.value = data.bairro;
        if (cidadeRef.current) cidadeRef.current.value = data.cidade;
        setCep(data.cep);
        setUf(data.uf);
    }

    useEffect(() => {
        if (!isEdit) {
            if (prop.isAdministrador) {
                Api.get<UsuarioPerfil>(`/PerfilAdministrador/Usuario/${prop.idUsuario}`)
                    .then(res => {
                        Verbose({ [`/PerfilAdministrador/Usuario/${prop.idUsuario}(response)`]: res.data })
                        setDados(res.data);
                    })
                    .catch(err => {
                        Verbose({ [`/PerfilAdministrador/Usuario/${prop.idUsuario})(catch)`]: err.response });
                    })
            } else {
                Api.get<UsuarioPerfil>("/Perfil")
                    .then((res) => {
                        Verbose({ "/Perfil(response)": res.data });
                        setDados(res.data)
                    })
                    .catch((err) => {
                        Verbose({ "/Pefil(catch)": err.response });
                    });
            }
        }
    }, [isEdit, prop.isAdministrador, prop.idUsuario]);

    function salvarPerfil() {
        const data = {
            nome: nomeRef.current?.value,
            dataNascimento: dataNascimentoRef.current?.value,
            telefone: telefoneRef.current?.value,
            cep,
            cidade: cidadeRef.current?.value,
            uf,
            complemento: complementoRef.current?.value,
            logradouro: logradouroRef.current?.value,
            numero: numeroRef.current?.value,
            bairro: bairroRef.current?.value
        }

        const dataAdmin = {
            idUsuario: prop.isAdministrador && prop.idUsuario,
            nome: nomeRef.current?.value,
            dataNascimento: dataNascimentoRef.current?.value,
            telefone: telefoneRef.current?.value,
            cep,
            cidade: cidadeRef.current?.value,
            uf,
            complemento: complementoRef.current?.value,
            logradouro: logradouroRef.current?.value,
            numero: numeroRef.current?.value,
            bairro: bairroRef.current?.value
        }
        if (
            nomeRef.current?.value &&
            dataNascimentoRef.current?.value &&
            telefoneRef.current?.value &&
            cep &&
            cidadeRef.current?.value &&
            uf &&
            complementoRef.current?.value &&
            logradouroRef.current?.value &&
            numeroRef.current?.value &&
            bairroRef.current?.value
        ) {
            if (prop.isAdministrador) {
                setLoad(true)
                Api.post("/PerfilAdministrador/Usuario", dataAdmin)
                    .then(() => {
                        setLoad(false)
                        toast({
                            title: "Dados salvos com sucesso!",
                            duration: 4000,
                            status: "success",
                            isClosable: true
                        })
                        setIsEdit(false);
                    })
                    .catch(err => {
                        setLoad(false)
                        Verbose({ "/Perfil(post)(catch)": err.response });
                    })
            } else {
                setLoad(true)
                Api.post("/Perfil", data)
                    .then(res => {
                        setLoad(false)
                        toast({
                            title: "Dados salvos com sucesso!",
                            duration: 4000,
                            status: "success",
                            isClosable: true
                        })
                        setIsEdit(false);
                    })
                    .catch(err => {
                        setLoad(false)
                        Verbose({ "/Perfil(post)(catch)": err.response });
                    })
            }
        } else {
            toast({
                title: "Preenche todos os campos.",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }

    return (
        <>
            <HStack mb="2" justifyContent="flex-end">
                {
                    isEdit && (
                        <>
                            <Button onClick={() => setIsEdit(false)}>
                                Cancelar
                            </Button>
                            <Button isLoading={load} variant="primary" onClick={() => salvarPerfil()}>
                                Salvar alterações
                            </Button>
                        </>
                    )
                }
                {
                    (prop.editavel && !isEdit) && (
                        <Button variant="primary" onClick={() => setIsEdit(true)}>
                            Editar
                        </Button>
                    )
                }
            </HStack>

            <Stack mb="20" spacing={4}>
                <Heading as="h2" size="md" mt="8" mb="4">
                    Informações pessoais
                </Heading>
                <HStack display={"flex"} flexDir={["column", "column", "row"]}>
                    <FormControl isDisabled={!isEdit} flex={3}>
                        <FormLabel htmlFor="nome">Nome Completo</FormLabel>
                        <Input id="nome" ref={nomeRef} type="text" />
                    </FormControl>
                </HStack>
                <HStack display={"flex"} flexDir={["column", "row"]}>
                    <FormControl isDisabled={true} flex={1}>
                        <FormLabel htmlFor="cpf">CPF</FormLabel>
                        <Input id="cpf" ref={cpfRef} type="text" maxW="190px" />
                    </FormControl>
                    <FormControl isDisabled={!isEdit} flex={1}>
                        <FormLabel htmlFor="dataNascimento">
                            Data de nascimento
                        </FormLabel>
                        <Input
                            id="dataNascimento"
                            ref={dataNascimentoRef}
                            type="date"
                            maxW="190px"
                        />
                    </FormControl>
                </HStack>

                <HStack
                    display={"flex"}
                    flexDir={["column", "row"]}
                    spacing={[0]}
                >
                    <FormControl isDisabled={!isEdit} flex={2}>
                        <FormLabel htmlFor="telefone">Telefone</FormLabel>
                        <Input
                            id="telefone"
                            ref={telefoneRef}
                            type="tel"
                            as={InputMask}
                            mask="(99) 99999-9999"
                            maxW="190px"
                        />
                    </FormControl>
                    <FormControl isDisabled={true} flex={2}>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input id="email" ref={emailRef} type="email" />
                    </FormControl>
                </HStack>

                <Divider />

                <Heading as="h2" size="md" mt="8" mb="4">
                    Endereço
                </Heading>
                <FormControl isDisabled={!isEdit} flex={1}>
                    <FormLabel htmlFor="cep">CEP</FormLabel>
                    <Input
                        onChange={(e) => setCep(e.target.value)}
                        id="cep"
                        as={InputMask}
                        mask="99999999"
                        value={cep}
                        type="text"
                        maxW="250px"
                    />
                </FormControl>
                <HStack spacing={4}>
                    <FormControl flex={2} isDisabled={!isEdit}>
                        <FormLabel htmlFor="cidade">Cidade</FormLabel>
                        <Input id="cidade" ref={cidadeRef} type="text" />
                    </FormControl>
                    <FormControl flex={1} isDisabled={!isEdit}>
                        <FormLabel htmlFor="bairro">Bairro</FormLabel>
                        <Input id="bairro" ref={bairroRef} type="text" />
                    </FormControl>
                </HStack>

                <HStack spacing={4}>
                    <FormControl flex={2} isDisabled={!isEdit}>
                        <FormLabel htmlFor="logradouro">Logradouro</FormLabel>
                        <Input id="logradouro" ref={logradouroRef} type="text" />
                    </FormControl>
                </HStack>
                <HStack spacing={4}>
                    <FormControl maxW="190px" flex={1} isDisabled={!isEdit}>
                        <FormLabel htmlFor="numero">Número</FormLabel>
                        <Input
                            id="numero"
                            ref={numeroRef}
                            type="number"
                        />
                    </FormControl>
                    <FormControl maxW="90px" flex={1} isDisabled={!isEdit}>
                        <FormLabel htmlFor="uf">UF</FormLabel>
                        <Input
                            onChange={(e) => setUf(e.target.value)}
                            id="uf"
                            value={uf}
                            type="text"
                            as={InputMask}
                            mask="aa"
                        />
                    </FormControl>
                </HStack>
                <HStack spacing={4}>
                    <FormControl flex={2} isDisabled={!isEdit}>
                        <FormLabel htmlFor="complemento">Complemento</FormLabel>
                        <Input id="complemento" ref={complementoRef} type="text" />
                    </FormControl>
                </HStack>
            </Stack>
        </>
    );
}

export default PerfilForm;