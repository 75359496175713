import { Button, HStack, MenuItem, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import Api from '../../api/Api';
import { Verbose } from '../../service/Verbose';
import CModal from '../CModal/Index';


type ModalEscluirUsuarioType = {
    idUsuario: string,
    updateList: () => void,
}

const ModalExcluirUsuario: React.FC<ModalEscluirUsuarioType> = (prop) => {
    const [load, setLoad] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const toast = useToast();
    function removerUsuario() {
        setLoad(true)
        Api.get(`perfilAdministrador/removerUsuario/${prop.idUsuario}`)
            .then(() => {
                setLoad(false);
                prop.updateList();
                setOpen(false)
                toast({
                    title: "Usuário removido com sucesso",
                    duration: 5000,
                    isClosable: true,
                    status: "success"
                })
            })
            .catch(err => {
                Verbose({ [`perfilAdministrador/removerUsuario/${prop.idUsuario}`]: err.response.data })
                setLoad(false);
            })
    }


    return (
        <>
            <MenuItem onClick={() => setOpen(true)}>
                Remover usuário
            </MenuItem>
            <CModal
                open={open}
                onClose={() => setOpen(false)}
                footer={
                    <HStack spacing={4}>
                        <Button onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>
                        <Button variant="primary" isLoading={load} onClick={() => removerUsuario()}>
                            Remover usuário
                        </Button>
                    </HStack>
                }
                title='Excluir usuário'
            >
                <Text>
                    Você tem certeza que deseja remover esse usuário no sistema?
                </Text>
            </CModal>
        </>
    );
}

export default ModalExcluirUsuario;