import React, { useContext } from "react";
import {
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  Text,
} from "@chakra-ui/react";
import { RolesContext } from "../../App";
import PerfilForm from "../../components/PerfilForm/Index";
import RoleIcon from "../../components/RoleIcon/Index";
import TraderForm from "../../components/PerfilForm/TraderForm";
const Perfil: React.FC = () => {
  //Formulário Editar
  const { roles } = useContext(RolesContext)

  return (
    <Container maxW={"container.md"}>
      <Tabs isFitted>
        <TabList>
          <Tab _selected={{ color: "primary.100", borderBottomColor: "primary.100" }}>
            <HStack>
              <Text>Dados principais</Text>
              <RoleIcon role="CLIENTE" color="primary.100" />
            </HStack>
          </Tab>
          {
            (roles.find(e => e === "TRADER")) && (
              <Tab _selected={{ color: "primary.100", borderBottomColor: "primary.100" }}>
                <HStack>
                  <Text>Dados trader</Text>
                  <RoleIcon role="TRADER" color="primary.100" />
                </HStack>
              </Tab>
            )
          }
        </TabList>

        <TabPanels>
          <TabPanel>
            <PerfilForm
              editavel
              isAdministrador={false}
            />
          </TabPanel>
          <TabPanel>
            <TraderForm
              isAdministrador={false}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container >
  );
};

export default Perfil;
