import moment from "moment"

export function isLogged(): boolean {
    const token: string = localStorage.getItem("@CI_TOKEN")!
    const exp: string = localStorage.getItem("@CI_EXP")!
    const role: string = localStorage.getItem("@CI_ROLE")!
    // console.log({
    //     token,
    //     exp,
    //     role,
    //     expDif: moment().diff(exp, "seconds")
    // })
    if (token && moment().diff(exp, "seconds") > 1 && role) {
        return true
    } else {
        return false
    }
}