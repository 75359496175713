import { Button, Collapse, FormControl, FormLabel, HStack, Input, Select, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../../api/Api';
import { RolesContext } from '../../App';
import { Verbose } from '../../service/Verbose';
import { Trader } from '../../types/OperaroesTypes';
import { TipoCorretagemEnum } from '../../types/PerfilTypes';

type TraderFormType = {
    isAdministrador: false
    idUsuario?: string | null | undefined
} | {
    isAdministrador: true
    idUsuario: string | null | undefined
}

const TraderForm: React.FC<TraderFormType> = (prop) => {
    const [codigo, setCodigo] = useState<string>("");
    const { roles } = useContext(RolesContext);
    const [tipoCorretagem, setTipoCorretagem] = useState<TipoCorretagemEnum>(0)
    const [valorCorretagem, setValorCorretagem] = useState<string>("")
    const [percentualDayTrade, setPercentualDayTrade] = useState<string>("")
    const [percentualBuyHold, setPercentualBuyHold] = useState<string>("")

    const [taxaAdministrativa, setTaxaAdministrativa] = useState<string>("")
    const [taxaBTC, setTaxaBTC] = useState<string>("")
    const [valorIndice, setValorIndice] = useState<string>("")
    const [valorDolar, setValorDolar] = useState<string>("")
    const [jurosSaldoNegativo, setJurosSaldoNegativo] = useState<string>("")
    const [taxaMesa, setTaxaMesa] = useState<string>("")

    const [edit, setEdit] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(false);
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        if (!edit) {

            if (prop.isAdministrador) {
                if (prop.isAdministrador && prop.idUsuario !== undefined && prop.idUsuario !== null) {
                    Api.get<Trader>(`/PerfilAdministrador/Trader/${prop.idUsuario}`)
                        .then(res => {
                            Verbose({ [`/PerfilAdministrador/Trader/${prop.idUsuario}`]: res.data });
                            setCodigo(res.data.subConta.toString())
                            setTipoCorretagem(res.data.tipoCorretagem)
                            setPercentualBuyHold(res.data.percentualBuyHold.toString());
                            setPercentualDayTrade(res.data.percentualDayTrade.toString());
                            setValorCorretagem(res.data.valorCorretagem.toString());

                            setTaxaBTC(res.data.taxaBTC.toString())
                            setValorIndice(res.data.valorIndice.toString())
                            setValorDolar(res.data.valorDolar.toString())
                            setJurosSaldoNegativo(res.data.jurosSaldoNegativo.toString())
                            setTaxaAdministrativa(res.data.taxaAdministracao.toString())
                            setTaxaMesa(res.data.taxaMesa.toString());
                        })
                        .catch(err => {
                            Verbose({ [`/Perfil/Administrador/Trader/${prop.idUsuario}(catch)`]: err.response });
                        })
                } else {
                    navigate("/usuarios")
                }
            } else {
                if (roles.find(e => e === "TRADER")) {
                    Api.get<Trader>(`Perfil/Trader/`)
                        .then(res => {
                            setCodigo(res.data.subConta.toString());
                            Verbose({ 'Perfil/Trader(response)': res.data });
                        })
                        .catch(err => {
                            Verbose({ "Perfil/Trader(catch)": err.response });
                        })
                }
            }
        }
    }, [navigate, prop.idUsuario, prop.isAdministrador, roles, edit])

    function editarTrader() {
        const data = {
            idUsuario: prop.isAdministrador && prop.idUsuario,
            subConta: parseInt(codigo),
            tipoCorretagem: tipoCorretagem,
            valorCorretagem,
            percentualBuyHold,
            percentualDayTrade,

            taxaBTC,
            valorIndice,
            valorDolar,
            jurosSaldoNegativo,
            taxaAdministracao: taxaAdministrativa,
            taxaMesa
        }
        setLoad(true)
        Verbose({ "/PerfilAdministrador/Trader(request)": data })
        Api.post("/PerfilAdministrador/Trader", data)
            .then(() => {
                setEdit(false);
                setLoad(false);

                toast({
                    title: "Perfil Editado",
                    status: "success",
                    isClosable: true,
                    duration: 3000
                })
            })
            .catch(err => {
                Verbose({ "/PerfilAdministrador/Trader": err.response });
                setLoad(false)
            })
    }

    return (
        <>
            <HStack spacing={4} mb="2" justifyContent="flex-end">
                {(!edit && prop.isAdministrador) && (
                    <Button variant="primary" onClick={() => setEdit(true)}>
                        Editar
                    </Button>
                )}

                {edit && (
                    <>
                        <Button onClick={() => setEdit(false)}>
                            Cancelar
                        </Button>

                        <Button variant="primary" isLoading={load} onClick={() => editarTrader()}>
                            Salvar
                        </Button>
                    </>
                )}
            </HStack>

            <HStack mb="20px" spacing={4}>
                <FormControl isDisabled={!edit}>
                    <FormLabel htmlFor="codigoSubConta">Código SubConta</FormLabel>
                    <Input
                        id="codigoSubConta"
                        type="text"
                        value={codigo === null ? "" : codigo}
                        onChange={(e) => setCodigo(e.target.value)}
                    />
                </FormControl>
            </HStack>

            {
                prop.isAdministrador && (
                    <>
                        <FormControl isDisabled={!edit}>
                            <FormLabel htmlFor="TipoCorretagem">Tipo de Corretagem</FormLabel>
                            <Select
                                placeContent="Fixo ou Volume"
                                id="TipoCorretagem"
                                value={tipoCorretagem}
                                onChange={(r) => {
                                    console.log(r.target.value)
                                    setTipoCorretagem(parseInt(r.target.value))
                                }}>
                                <option value={TipoCorretagemEnum.FIXA}>FIXA</option>
                                <option value={TipoCorretagemEnum.VOLUME}>VOLUME</option>
                            </Select>
                        </FormControl>

                        <Collapse in={tipoCorretagem === TipoCorretagemEnum.FIXA} animateOpacity>
                            <FormControl isDisabled={!edit}>
                                <FormLabel htmlFor="ValorCorretagem">Valor da Corretagem</FormLabel>
                                <Input
                                    id="ValorCorretagem"
                                    type="number"
                                    value={valorCorretagem}
                                    onChange={(e) => setValorCorretagem(e.target.value)}
                                />
                            </FormControl>
                        </Collapse>

                        <Collapse in={tipoCorretagem === TipoCorretagemEnum.VOLUME} animateOpacity>
                            <FormControl isDisabled={!edit}>
                                <FormLabel htmlFor="percentualDayTrade">Percentual DayTrade</FormLabel>
                                <Input
                                    id="percentualDayTrade"
                                    type="number"
                                    value={percentualDayTrade}
                                    onChange={(e) => setPercentualDayTrade(e.target.value)}
                                />
                            </FormControl>
                            <FormControl isDisabled={!edit}>
                                <FormLabel htmlFor="percentualBuyHold">Percentual BuyHold</FormLabel>
                                <Input
                                    id="percentualBuyHold"
                                    type="number"
                                    value={percentualBuyHold}
                                    onChange={(e) => setPercentualBuyHold(e.target.value)}
                                />
                            </FormControl>
                        </Collapse>

                        <FormControl isDisabled={!edit}>
                            <FormLabel htmlFor="taxaAdministrativa">Taxa Administrativa</FormLabel>
                            <Input
                                id="taxaAdministrativa"
                                type="number"
                                value={taxaAdministrativa}
                                onChange={(e) => setTaxaAdministrativa(e.target.value)}
                            />
                        </FormControl>
                        <FormControl isDisabled={!edit}>
                            <FormLabel htmlFor="valorIndice">Valor Índice</FormLabel>
                            <Input
                                id="valorIndice"
                                type="number"
                                value={valorIndice}
                                onChange={(e) => setValorIndice(e.target.value)}
                            />
                        </FormControl>
                        <FormControl isDisabled={!edit}>
                            <FormLabel htmlFor="taxaBTC">Taxa BTC</FormLabel>
                            <Input
                                id="taxaBTC"
                                type="number"
                                value={taxaBTC}
                                onChange={(e) => setTaxaBTC(e.target.value)}
                            />
                        </FormControl>
                        <FormControl isDisabled={!edit}>
                            <FormLabel htmlFor="valorDolar">Valor Dólar</FormLabel>
                            <Input
                                id="valorDolar"
                                type="number"
                                value={valorDolar}
                                onChange={(e) => setValorDolar(e.target.value)}
                            />
                        </FormControl>
                        <FormControl isDisabled={!edit}>
                            <FormLabel htmlFor="taxaMesa">Taxa Mesa</FormLabel>
                            <Input
                                id="taxaMesa"
                                type="number"
                                value={taxaMesa}
                                onChange={(e) => setTaxaMesa(e.target.value)}
                            />
                        </FormControl>
                        <FormControl isDisabled={!edit}>
                            <FormLabel htmlFor="jurosSaldoNegativo">Juros saldo Negativo</FormLabel>
                            <Input
                                id="jurosSaldoNegativo"
                                type="number"
                                value={jurosSaldoNegativo}
                                onChange={(e) => setJurosSaldoNegativo(e.target.value)}
                            />
                        </FormControl>
                    </>
                )
            }

        </>
    );
}

export default TraderForm;