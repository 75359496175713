import React, { useContext, useState } from 'react';
import {
    Box,
    Container,
    Text,
    Image,
    Stack,
    HStack,
    Button,
    ButtonGroup,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from '@chakra-ui/react'
import logo from '../../assets/logo.png';
import { useLocation, useNavigate } from 'react-router-dom'
import CModal from '../CModal/Index';
import { RolesContext } from '../../App';
import ModalImportarCSV from '../ModalImportarCSV/Index';
import { IoIosArrowForward } from 'react-icons/io'
type ClickType = {
    text: string
    path: string
}

type isUsuarioId = { idUsuario: string, email: string, traderId: string }

const CLink: React.FC<ClickType> = (prop) => {
    const nav = useNavigate();
    const { state } = useLocation()
    const { idUsuario, email, traderId } = state ? state as isUsuarioId : { idUsuario: "", email: "", traderId: "" }

    return (
        <Text
            fontSize="0.92rem"
            variant="link"
            color={(window.location.pathname === prop.path &&
                (idUsuario === "" || idUsuario === undefined) &&
                (email === "" || email === undefined) &&
                (traderId === "" || traderId === undefined)
            ) ? "secondary.100" : "primary.100"}
            onClick={() => nav(prop.path,
                {
                    state: { idUsuario: undefined, email: undefined },
                    replace: true
                })}>{prop.text}</Text>
    );
}


const NavbarWrapper: React.FC = () => {
    const [modalClose, setModalClose] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false);
    const { resetRoles, roles } = useContext(RolesContext);
    const navigate = useNavigate();

    function Sair() {
        localStorage.removeItem("@CI_TOKEN");
        localStorage.removeItem("@CI_ROLE");
        localStorage.removeItem("@CI_EXP");
        resetRoles();
        navigate('/login');
    }

    return (
        <>
            <Box bg="white.100" p="2">
                <Container maxW="5xl" display="flex" w="100%" justifyContent="space-between">
                    <Image src={logo} h="64px" />
                    <Stack direction="row" spacing={6} justifyContent="space-around" alignItems="center">
                        {roles.find(e => e === "ADMINISTRADOR") && <CLink path='/usuarios' text='Usuários' />}
                        {
                            roles.every(e => e === "CLIENTE" || e === "TRADER") &&
                            <CLink path="/operacoes" text="Tela Principal" />
                        }

                        {
                            roles.every(e => e === "TRADER") &&
                            <>
                                <CLink path="/custodia" text="Custódias" />
                                <CLink path="/saldo" text="Saldo" />
                            </>
                        }

                        <CLink path='/perfil' text="Perfil" />

                        {
                            roles.find(e => e === "ADMINISTRADOR") && (
                                <CLink path="/saldoEmpresa" text="Saldo empresa" />
                            )
                        }

                        {
                            roles.find(e => e === "ADMINISTRADOR") && (
                                <ButtonGroup size="sm" isAttached variant="primary-outline">
                                    <Button mr="-px" onClick={() => setOpen(true)}>
                                        Importar arquivos
                                    </Button>
                                    <Menu>
                                        <MenuButton ml="-px" as={Button} >
                                            <IoIosArrowForward />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => navigate('/historicoMovimentacao')}>
                                                Histórico de importação
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </ButtonGroup>
                            )
                        }
                        <Button onClick={() => setModalClose(true)} variant="primary" size="sm">
                            Sair
                        </Button>
                    </Stack>
                </Container>
            </Box>
            <CModal
                open={modalClose}
                onClose={() => setModalClose(false)}
                footer={
                    <HStack spacing={4}>
                        <Button onClick={() => setModalClose(false)}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => Sair()}>
                            Sair
                        </Button>
                    </HStack>
                }
                title='Sair'
            >
                <Text>Tem certeza de que deseja sair da plataforma?</Text>
            </CModal>
            <ModalImportarCSV open={open} onClose={() => setOpen(false)} />
        </>
    );
}

export default NavbarWrapper;