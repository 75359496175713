import { Center, Image } from "@chakra-ui/react";
import React from "react";
import logoImage from "../../assets/model-woman.png";

const ContanierImageAuth: React.FC = () => {
  return (
    <Center
      bg="darkBlue.100"
      h="100vh"
      w="100vw"
      position={["absolute", "absolute", "unset"]}
      top="-150%"
    >
      <Image
        boxSize={"100%"}
        objectFit="cover"
        alt="model-woman"
        src={logoImage}
      />
    </Center>
  );
};

export default ContanierImageAuth;
