import { Box, Center, Container, Flex, Heading, HStack, Icon, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Spinner, Text, Tooltip } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Api from '../../api/Api';
import { UsuarioAdminItem } from '../../types/UsuarioTypes';
import { MdSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import { CgMenu } from 'react-icons/cg'
import ValidateUsuarios, { UsuarioPerfilUsers } from '../../service/ValidateUsuarios';
import { Verbose } from '../../service/Verbose';
import RoleIcon from '../../components/RoleIcon/Index';
import ModalExcluirUsuario from '../../components/ModalExcluirUsuario/Index';
const Usuarios: React.FC = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState<boolean>(true)
  const [usuarios, setUsuarios] = useState<UsuarioPerfilUsers[]>([]);
  const [pesquisa, setPesquisa] = useState<string>("");
  const searchUsers: UsuarioPerfilUsers[] = usuarios.filter(e =>
    e.nome.toUpperCase().includes(pesquisa.toUpperCase()) ||
    e.email.toUpperCase().includes(pesquisa.toUpperCase()) ||
    e.subconta.includes(pesquisa));


  const listarUsuarios = useCallback(() => {
    setLoad(true);
    Api.get<UsuarioAdminItem[]>("/PerfilAdministrador/Usuarios")
      .then(res => {
        setLoad(false);
        Verbose({ "/PerfilAdministrador/Usuarios(response)": res.data });
        setUsuarios(ValidateUsuarios(res.data));
      })
      .catch(err => {
        Verbose({ "/PerfilAdministrador/Usuarios(catch)": err.response });
      })
  }, [])

  useEffect(() => {
    listarUsuarios();
  }, [listarUsuarios])

  const columns: TableColumn<UsuarioPerfilUsers>[] = [
    {
      grow: 1.5,
      name: "Nome",
      selector: (row) => row.nome,
      sortable: true,
      compact: true
    },
    {
      grow: 2,
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => (
        <Tooltip label={row.email} maxW="100vw">
          <Text isTruncated>{row.email}</Text>
        </Tooltip>
      ),
      sortable: true,
      compact: true,
    },
    {
      grow: 1,
      id: "subconta",
      name: "SubConta",
      selector: (row) => row.subconta,
      sortable: true,
      compact: true,
    },
    {
      grow: 1,
      name: "CPF",
      selector: (row) => row.cpf,
      sortable: true,
      compact: true,
    },
    {
      grow: 1,
      name: "Perfil",
      maxWidth: "80px",
      compact: true,
      selector: (row) => row.perfil[0],
      cell: (row) => (
        <>
          <HStack spacing={2} position="relative">
            {
              row.perfil?.map(e =>
                <Tooltip key={e} size="sm" label={e} shouldWrapChildren>
                  <RoleIcon role={e} color="primary.100" />
                </Tooltip>
              )
            }
          </HStack>
        </>
      )
    },
    {
      name: "Ações",
      grow: .6,
      cell: (row) => (
        <Menu strategy='fixed'>
          <MenuButton>
            <Icon as={CgMenu} color="primary.100" h="18px" />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => navigate("/perfilAdmin", { state: { id: row.id, nome: row.nome, roles: row.perfil } })}>
              Ver perfil
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/operacoes", { state: { email: row.email, nome: row.nome } })}>
              Movimentações
            </MenuItem>
            {
              row.perfil.find(e => e === "TRADER") && (
                <MenuItem
                  onClick={() => navigate("/custodia", { state: { idUsuario: row.id, nome: row.nome } })}>
                  Custódias
                </MenuItem>
              )
            }
            {
              row.perfil.find(e => e === "TRADER") && (
                <MenuItem
                  onClick={() => navigate("/saldo", { state: { traderId: row?.traderId, nome: row.nome } })}>
                  Saldo
                </MenuItem>
              )
            }

            <ModalExcluirUsuario idUsuario={row.id} updateList={() => listarUsuarios()} />

          </MenuList>
        </Menu>
      )
    }
  ]

  return (
    <Container maxW="5xl">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pb="8"
      >
        <Heading size="lg" pr="4">
          Usuários
        </Heading>
        <Box w="50%">
          <InputGroup>
            <Input
              placeholder='Pesquisar por nome, email ou subconta...'
              value={pesquisa}
              onChange={(e) => setPesquisa(e.target.value)}
            />
            <InputRightElement children={<Icon as={MdSearch} color="primary.100" />} />
          </InputGroup>
        </Box>
      </Flex>
      <Box bg='white.100' p='8' w="100%" borderRadius="16px" boxShadow="0px  8px 28px #00000006" >
        <DataTable
          highlightOnHover
          defaultSortFieldId="subconta"
          defaultSortAsc={false}
          customStyles={{
            responsiveWrapper: {
              style: {
                overflowY: "inherit",
                overflowX: "inherit"
              }
            }
          }}
          columns={columns}
          data={searchUsers}
          pagination
          progressPending={load}
          progressComponent={
            <Center p="20">
              <Spinner
                w="80px"
                h="80px"
                color="primary.100"
                thickness='8px'
              />
            </Center>
          }
          noDataComponent={
            <Center p="3">
              <Text>Usuário não encontrado.</Text>
            </Center>
          }
          paginationComponentOptions={{
            rowsPerPageText: "Email por página",
            rangeSeparatorText: "de"
          }}
        />
      </Box>
    </Container>
  );
}

export default Usuarios;