export function converterData(data: string, inverse: boolean) {
    if(inverse) {
        const spliterDate = data.split('/');
        const orderDate = `${spliterDate[2]}-${spliterDate[1]}-${spliterDate[0]}`
        return orderDate;
    } else {
        const spliterDate = data.split('-');
        const orderDate = `${spliterDate[0]}/${spliterDate[1]}/${spliterDate[2]}`
        return orderDate;
    }
  }