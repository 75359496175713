import { Box, Button, Center, Flex, Icon, Spinner, Text, useTheme } from '@chakra-ui/react';
import React, { useContext } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import { BiExpand } from 'react-icons/bi'
import { FiMinimize } from 'react-icons/fi'
import { SizeContext } from '../../pages/Main/Operacoes';
import { extrato } from '../../types/OperaroesTypes';

type ListaExtratoType = {
    visible: boolean
    load: boolean
    lista: extrato[]
}


const ListaExtrato: React.FC<ListaExtratoType> = (prop) => {
    const { isSize, toggleSize } = useContext(SizeContext);
    const theme = useTheme();
    const columnExtrato: TableColumn<extrato>[] = [
        {
            name: "Dia",
            selector: (row) => row.dia,
            sortable: true,
            width: "48px",
            compact: true
        },
        {
            name: "Fatura",
            selector: (row) => row.receita,
            sortable: true,
            compact: true
        },
        {
            name: "Taxa Mesa",
            selector: (row) => row.taxaMesa,
            sortable: true,
            compact: true
        },
        {
            name: "Plataforma",
            selector: (row) => row.plataforma,
            sortable: true,
            compact: true
        },
        {
            name: "Líquido",
            selector: (row) => row.liquido,
            sortable: true,
            compact: true
        },
        {
            name: "BTC",
            selector: (row) => row.btc,
            sortable: true,
            compact: true
        },
        {
            name: "Entrada",
            selector: (row) => row.deposito,
            sortable: true,
            compact: true
        },
        {
            name: "Saída",
            selector: (row) => row.retirada,
            sortable: true,
            compact: true,
            style: {
                borderRight: `3px solid ${theme.colors.secondary["100"]}`
            }
        },
        {
            name: "PL",
            selector: (row) => row.pvl,
            sortable: true,
            compact: true,

        },
        // {
        //     name: "Parceria",
        //     selector: (row) => row.parceria,
        //     sortable: true,
        //     compact: true
        // },
        // {
        //     name: "Gestor",
        //     selector: (row) => row.gestor,
        //     sortable: true,
        //     compact: true
        // },
        {
            name: "Porcentagem",
            selector: (row) => row.porcentagem,
            sortable: true,
            compact: true,
            style: {
                borderRight: `3px solid ${theme.colors.secondary["100"]}`
            }
        },
        {
            name: "Estorno",
            selector: (row) => row.taxaIndicacao,
            sortable: true,
            compact: true
        },
        {
            name: "SubTotal",
            selector: (row) => row.subtotal,
            sortable: true,
            compact: true
        },
        {
            name: "Saldo",
            selector: (row) => row.saldo,
            sortable: true,
            compact: true
        },
    ]

    return (
        <Box display={prop.visible ? "block" : "none"}>
            <Flex justifyContent="space-between" alignItems="center">
                <Text pb="4" pt="4" fontWeight="bold" fontSize="1.4rem">Extratos</Text>
                <Button
                    variant="primary-outline"
                    onClick={() => toggleSize()}
                >
                    <Icon as={isSize ? FiMinimize : BiExpand} />
                </Button>
            </Flex>
            <Box bg='white.100' p='8' borderRadius="16px" boxShadow="0px  8px 28px #00000006" >
                <DataTable
                    columns={columnExtrato}
                    data={prop.lista}
                    progressPending={prop.load}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight='50vh'
                    customStyles={{
                        cells: {
                            style: {
                                fontSize: "10px",
                                fontWeight: "bold"
                            }
                        }
                    }}
                    noDataComponent={
                        <Center p="8">
                            <Text>Nenhum extrato encontrado.</Text>
                        </Center>
                    }
                    progressComponent={
                        <Center p="20">
                            <Spinner
                                w="80px"
                                h="80px"
                                color="primary.100"
                                thickness='8px'
                            />
                        </Center>
                    }
                />
            </Box>
        </Box>
    );
}

export default ListaExtrato;