import { Button, Center, HStack, Icon, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Api from '../../api/Api';
import CModal from '../CModal/Index';
import { MdFileCopy } from 'react-icons/md'
import ModalConfirmarDados from './ModalConfirmarDados';
import { importacaoValidado } from '../../types/ImportacaoTypes';
import { Verbose } from '../../service/Verbose';


type ModalImportarCSVType = {
    open: boolean
    onClose: () => any
}

const ModalImportarCSV: React.FC<ModalImportarCSVType> = ({ open, onClose }) => {
    const [checked, setChecked] = useState<boolean>(false)
    const [pasta, setPasta] = useState<File | null>(null);
    const pastaRef = useRef<HTMLInputElement>(null)
    const [load, setLoad] = useState<boolean>(false)
    const [listaProcessada, setListaProcessada] = useState<importacaoValidado>(
        {
            data: "",
            lista: [],
            messageStatus: "",
            status: "success"
        }
    )
    const toast = useToast();
    useEffect(() => {
        if (!open) {
            setPasta(null);
        }
    }, [open])

    function processarArquivo() {
        if (pasta) {
            setLoad(true);
            const data = new FormData();
            data.append("planilha", pasta)
            Verbose({ "/ImportacaoCsv/ImportacaoCsv(request)": data });
            Api.post<importacaoValidado>("/ImportacaoCsv/ImportacaoCsv", data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                setListaProcessada(res.data);
                    setChecked(true)
                    setLoad(false);
                })
                .catch(err => {
                    Verbose({ "/ImportacaoCsv/ImportacaoCsv(catch)": err.response })
                    setLoad(false);
                    toast({
                        title: "Erro na leitura do arquivo.",
                        status: "error",
                        isClosable: true,
                        duration: 5000
                    })
                })
        } else {
            toast({
                title: "Selecione um arquivo csv",
                status: "error",
                duration: 4000,
                isClosable: true
            })
        }
    }

    return (
        <>
            <CModal
                open={open}
                onClose={onClose}
                title='Importar arquivo'
                footer={
                    <HStack spacing={4}>
                        <Button onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                processarArquivo();
                            }}
                            isLoading={load}
                            disabled={pasta === null}
                        >
                            Enviar
                        </Button>
                    </HStack>
                }
            >
                <>
                    <input
                        accept=".csv"
                        type="file"
                        style={{ display: "none" }}
                        ref={pastaRef}
                        onAbort={() => setPasta(null)}
                        onChange={(e) => {
                            if (e.currentTarget.files) {
                                if (e.currentTarget.files[0]) {
                                    setPasta(e.currentTarget.files[0])
                                } else {
                                    setPasta(null);
                                }
                            }
                        }}
                    />
                    <Center
                        cursor="pointer"
                        onClick={() => pastaRef.current?.click()}
                        p="8"
                        border="solid 1px"
                        borderRadius="12px"
                        borderColor="#dadada"
                        flexDirection="column"
                    >
                        <Icon as={MdFileCopy} boxSize="12" color={pasta ? "primary.100" : "#dadada"} />
                        {
                            pasta ?
                                <Text color="primary.100">{pasta.name}</Text>
                                :
                                <Text>Selecione um arquivo .csv</Text>
                        }
                    </Center>
                </>
            </CModal>

            <ModalConfirmarDados
                open={checked}
                onClose={() => setChecked(false)}
                list={listaProcessada}
                onConfirm={() => {
                    setChecked(false)
                    onClose()
                }}
            />
        </>
    );
}

export default ModalImportarCSV;