import { ChakraProvider } from '@chakra-ui/react';
import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectRouteSystem, { roles } from './components/ProtectRouteSystem';
import DashboardContainer from './components/Wrapper/DashboardContainer';
import defaultStyle from './style/defaultStyle';
import EsqueciSenha from './pages/Auth/EsqueciSenha';
import Login from './pages/Auth/Login';
import RedefinirSenha from './pages/Auth/RedefinirSenha';
import Operacoes from './pages/Main/Operacoes';
import Perfil from './pages/Main/Perfil';
import Carregando from './pages/Carregando';
import Registrar from './pages/Auth/Registrar';
import PerfilAdmin from './pages/Main/PerfilAdmin';
import NotFound from './pages/NotFound';
import Usuarios from './pages/Main/Usuarios';
import HistoricoMovimentacao from './pages/Main/HistoricoMovimentacao';
import Custodia from './pages/Main/Custodia';
import { Verbose } from './service/Verbose';
import SaldoTela from './pages/Main/SaldoTela';
import SaldoEmpresa from './pages/SaldoEmpresa';

type roleContextType = {
  roles: roles[]
  resetRoles: () => void,
  setRoles: (e: roles) => any
}

export const RolesContext = createContext<roleContextType>({
  roles: [],
  setRoles: () => { },
  resetRoles: () => { },
})

const App: React.FC = () => {
  const [userRole, setUserRole] = useState<roles[]>([]);

  function resetRoles() {
    setUserRole([])
  }

  function setRoles(e: string) {
    if (!localStorage.getItem("@CI_ROLE")) {
      const rolesArray: roles[] = e.split(',') as roles[];
      const rolesArrayJSON: string = JSON.stringify(rolesArray);
      localStorage.setItem("@CI_ROLE", rolesArrayJSON);
      setUserRole(rolesArray);
    }
  }

  useEffect(() => {
    const roles = localStorage.getItem("@CI_ROLE")!;
    if (roles) {
      const roleParse: roles[] = JSON.parse(roles);
      Verbose({ roleParse, "isArray": Array.isArray(roleParse) })
      setUserRole(roleParse)
    }
  }, [])

  return (
    <ChakraProvider resetCSS theme={defaultStyle}>
      <BrowserRouter>
        <RolesContext.Provider value={{ roles: userRole, resetRoles, setRoles }}>
          <Routes>
            <Route path="/*" element={<NotFound />} />
            <Route path='/login' element={<Login />} />
            <Route path='/registrar' element={<Registrar />} />
            <Route path='/esqueciSenha' element={<EsqueciSenha />} />
            <Route path='/acesso/ResetarSenha' element={<RedefinirSenha />} />
            <Route path='/' element={<Carregando />} />

            <Route element={<DashboardContainer />} path="/" >
              <Route path="/usuarios" element={<ProtectRouteSystem children={<Usuarios />} roles={["ADMINISTRADOR"]} />} />
              <Route path='/operacoes' element={<ProtectRouteSystem children={<Operacoes />} roles={[]} />} />
              <Route path='/perfil' element={<ProtectRouteSystem children={<Perfil />} roles={[]} />} />
              <Route path='/perfilAdmin' element={<ProtectRouteSystem children={<PerfilAdmin />} roles={["ADMINISTRADOR"]} />} />
              <Route path='/custodia' element={<ProtectRouteSystem children={<Custodia />} roles={[]} />} />
              <Route path='/saldo' element={<ProtectRouteSystem children={<SaldoTela />} roles={[]} />} />
              <Route path='/saldoEmpresa' element={<ProtectRouteSystem children={<SaldoEmpresa />} roles={["ADMINISTRADOR"]} />} />
              <Route path='/historicoMovimentacao' element={<ProtectRouteSystem children={<HistoricoMovimentacao />} roles={["ADMINISTRADOR"]} />} />
            </Route>
          </Routes>
        </RolesContext.Provider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;