type EnderecoReturnResponseType = {
    cep: string
    logradouro: string,
    complemento: string,
    bairro: string,
    localidade: string,
    uf: string,
    ibge: number,
    gia: number,
    ddd: number,
    siafi: number
}

export async function EnderecoFind(cep: string): Promise<EnderecoReturnResponseType | undefined> {
    if (cep.length === 8 && cep.search("_") === -1) {
        const response: EnderecoReturnResponseType = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json())
            .catch(err => {
                console.log({ [`https://viacep.com.br/ws/${cep}/json/`]: err });
            })
        if (response.cep) {
            return response;
        } else {
            return undefined
        }
    }
}