import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Image,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import Api from "../../api/Api";
import validateEmail from "../../service/ValidateEmail";
import Logo from "../../assets/logo.png";
import ContanierImageAuth from "../../components/ContanierImageAuth/ContanierImageAuth";
import { Verbose } from "../../service/Verbose";
import { useRef } from "react";


const EsqueciSenha: React.FC = () => {
  const navigate = useNavigate();
  // const email = useRef(null);
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const enviarRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();

  function enviarCodigo() {
    setLoad(true);
    if (email !== "" && validateEmail(email)) {
      Api.get("Acesso/EsqueciSenha?email=" + email)
        .then((res) => {
          setLoad(false);
          navigate("/Login", {
            state: {
              email,
              code: res.data,
            },
          });
          toast({
            title:
              "O código de redefinição de senha foi enviado em sua caixa de mensagens",
            duration: 8000,
            isClosable: true,
            status: "info",
          });
        })
        .catch((err) => {
          setLoad(false);
          Verbose({ "api/Acesso/EsqueciSenha(catch)": err.response });
          if (err.response.data === "Usuario não encontrado.") {
            toast({
              title: "Email não foi encontrado",
              duration: 4000,
              status: "error",
              isClosable: true,
            });
          }
        });
    } else {
      setLoad(false);
      toast({
        title: "Email Inválido",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }

  return (
    <>
      <SimpleGrid columns={2} display={{ md: "flex" }}>
        <Box
          bg="white"
          width={["100vw", "100vw", "50vw", "40vw"]}
          height="100vh"
          padding="5rem"
          flexDirection="column"
          flexShrink={0}
        >
          <Box
            marginBottom="5em"
            display="flex"
            justifyContent={["center", "center", "start"]}
          >
            <Image src={Logo} w="240px" alt="A logo da criterio investimento" />
          </Box>

          <Box>
            <Text marginBottom="3em">
              Enviaremos por email um link de redefinição de senha. Informe
              abaixo o email cadastrado.
            </Text>
            <FormControl color="black">
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                onChange={(Eu) => setEmail(Eu.target.value)}
                size="lg"
                id="email"
                type="email"
                borderColor="gray"
                onKeyDown={(e) => {
                  if (e.key === "Enter")
                    enviarRef.current?.click();
                }}
              />
            </FormControl>
            <Box
              display="flex"
              flexDirection={["column", "column", "row-reverse"]}
              marginTop="2em"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                marginBottom={["1.6em", "1.6em", "0"]}
                width={["100%", "100%", "auto"]}
                variant="primary"
                size="lg"
                ref={enviarRef}
                isLoading={load}
                onClick={() => enviarCodigo()
                }
              >
                Enviar link
              </Button>
              <Button
                width={["100%", "100%", "auto"]}
                size="lg"
                onClick={() => navigate("/Login")}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
        <ContanierImageAuth />

        {/* <Center
          bg="darkBlue.100"
          w="100%"
          h="100vh"
          p="8"
          position={["absolute", "absolute", "unset"]}
          top="-150%"
        >
          <Image src={loginIlustration} />
        </Center> */}
      </SimpleGrid>
    </>
  );
};

export default EsqueciSenha;
