import { Button, FormControl, FormLabel, HStack, Input, Stack, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../../api/Api';
import { RolesContext } from '../../App';
import { Verbose } from '../../service/Verbose';
import { Indicacao } from '../../types/OperaroesTypes';


type IndicadorFormType = {
  isAdministrador: false
  idUsuario?: string | null | undefined
} | {
  isAdministrador: true
  idUsuario: string | null | undefined
}


const IndicadorForm: React.FC<IndicadorFormType> = (prop) => {
  const [subContaIndicador, setSubContaIndicador] = useState<string>("")
  const [taxaComissao, setTaxaComissao] = useState<string>("")
  const [edit, setEdit] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)
  const navigate = useNavigate();
  const { roles } = useContext(RolesContext);
  const toast = useToast();
  useEffect(() => {
    if (prop.isAdministrador) {
      if (prop.isAdministrador && prop.idUsuario !== undefined && prop.idUsuario !== null) {
        Verbose([`perfilAdministrador/indicador/${prop.idUsuario}(request)`])
        Api.get<Indicacao>(`perfilAdministrador/indicador/${prop.idUsuario}`)
          .then(res => {
            setSubContaIndicador(res.data.subContaIndicador.toString())
            setTaxaComissao(res.data.taxaComissao.toString())
          })
          .catch(err => {
            Verbose({ [`perfilAdministrador/indicador/${prop.idUsuario}`]: err.response?.data })
            if (err.response?.data === "INDICACAO_NAO_ENCONTRADA")
              toast({
                title: "Indicador não encontrada.",
                status: "info",
                duration: 5000,
                isClosable: true
              })
          })
      } else {
        navigate("usuarios")
      }

    } else {
      if (roles.find(e => e === "TRADER")) {
        Api.get<Indicacao>("perfil/indicador")
          .then(res => {
            setSubContaIndicador(res.data.subContaIndicador.toString())
            setTaxaComissao(res.data.taxaComissao.toString())
          })
          .catch(err => {
            Verbose({ "perfil/Indicador": err.response.data })
          })
      }
    }
  }, [toast, prop.idUsuario, prop.isAdministrador, navigate, roles])

  function editarIndicador() {
    const data = {
      idUsuario: prop.idUsuario,
      subContaIndicador,
      taxaComissao
    }
    setLoad(true);
    Api.post(`perfilAdministrador/indicador/${prop.idUsuario}`, data)
      .then(() => {
        setLoad(false);
        toast({
          title: "Dados de indicação atualizados.",
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      })
      .catch(err => {
        Verbose({ [`perfilAdministrador/indicador/${prop.idUsuario}(catch)`]: err.response.data })
        setLoad(false);
      })
  }

  return (
    <>
      <HStack spacing={4} mb="2" justifyContent="flex-end">
        {
          edit ? (
            <>
              <Button onClick={() => setEdit(false)}>
                Cancelar
              </Button>
              <Button variant="primary" isLoading={load} onClick={() => editarIndicador()}>
                Salvar
              </Button>
            </>
          ) : (
            <>
              <Button variant="primary" onClick={() => setEdit(true)}>
                Editar
              </Button>
            </>
          )
        }
      </HStack>

      <Stack spacing="4">
        <FormControl isDisabled={!edit} >
          <FormLabel>Subconta do indicador</FormLabel>
          <Input value={subContaIndicador} onChange={(e) => setSubContaIndicador(e.target.value)} />
        </FormControl>

        <FormControl isDisabled={!edit} >
          <FormLabel>Taxa de comissão</FormLabel>
          <Input value={taxaComissao} onChange={(e) => setTaxaComissao(e.target.value)} />
        </FormControl>

      </Stack>
    </>
  );
}

export default IndicadorForm;