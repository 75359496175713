import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import InputMask from "react-input-mask";
import logoImage from '../../assets/model-woman.png'
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";

import { EnderecoFind } from "../../service/EnderecoFind";
import Api from "../../api/Api";

// import loginIlustration from "../../assets/login_ilustration.svg";
import Logo from "../../assets/logo.png";
import { Verbose } from "../../service/Verbose";
import InputPassword from "../../components/InputPassword/Index";
import { converterData } from "../../service/ConverterData";

const Registrar: React.FC = () => {
  const [load, setLoad] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  //Formulário registrar
  const nomeCompletoRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const senhaRef = useRef<HTMLInputElement>(null);
  const confirmarSenhaRef = useRef<HTMLInputElement>(null);
  const dataNascimentoRef = useRef<HTMLInputElement>(null);
  const telefoneRef = useRef<HTMLInputElement>(null);
  const logradouroRef = useRef<HTMLInputElement>(null);
  const numeroRef = useRef<HTMLInputElement>(null);
  const complementoRef = useRef<HTMLInputElement>(null);
  const bairroRef = useRef<HTMLInputElement>(null);
  const cidadeRef = useRef<HTMLInputElement>(null);
  const ufRef = useRef<HTMLInputElement>(null);
  const cepRef = useRef<HTMLInputElement>(null);
  const cpfRef = useRef<HTMLInputElement>(null);
  // const subContaRef = useRef<HTMLInputElement>(null);
  const [loadCEP, setLoadCEP] = useState<boolean>(false);
  async function findEnderecoToCEP() {
    setLoadCEP(true);
    const enderecos = await EnderecoFind(cepRef.current?.value!);
    if (enderecos) {
      if (logradouroRef.current)
        logradouroRef.current.value = enderecos.logradouro;
      if (complementoRef.current)
        complementoRef.current.value = enderecos.complemento;
      if (bairroRef.current) bairroRef.current.value = enderecos.bairro;
      if (cidadeRef.current) cidadeRef.current.value = enderecos.localidade;
      if (ufRef.current) ufRef.current.value = enderecos.uf;
      setLoadCEP(false);
    } else {
      toast({
        title: "CEP",
        description: "cep inválido ou não encontrado.",
        status: 'info',
        duration: 2000
      })
      setLoadCEP(false);
    }
  }

  function registrar() {

    const data = {
      cpf: cpfRef.current?.value,
      nome: nomeCompletoRef.current?.value,
      senha: senhaRef.current?.value,
      login: emailRef.current?.value,
      // codigoSubConta: subContaRef.current?.value,
      telefone: telefoneRef.current?.value,
      endereco: {
        logradouro: logradouroRef.current?.value,
        numero: parseInt(numeroRef.current?.value!),
        complemento: complementoRef.current?.value,
        bairro: bairroRef.current?.value,
        cidade: cidadeRef.current?.value,
        uf: ufRef.current?.value,
        cep: cepRef.current?.value,
      },
      dataNascimento: converterData(dataNascimentoRef.current?.value!, true)
    };

    if (
      nomeCompletoRef.current?.value !== "" &&
      emailRef.current?.value !== "" &&
      dataNascimentoRef.current?.value !== "" &&
      telefoneRef.current?.value !== "" &&
      cepRef.current?.value !== "" &&
      logradouroRef.current?.value !== "" &&
      numeroRef.current?.value !== "" &&
      // complementoRef.current?.value !== "" &&
      bairroRef.current?.value !== "" &&
      cidadeRef.current?.value !== "" &&
      ufRef.current?.value !== "" &&
      senhaRef.current?.value !== "" &&
      confirmarSenhaRef.current?.value !== ""
    ) {
      if (senhaRef.current?.value === confirmarSenhaRef.current?.value) {
        if (nomeCompletoRef.current?.value.split(" ")[1]) {
          if (/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/.test(dataNascimentoRef.current?.value!)) {
            Verbose({ "/Acesso/Cadastro(request)": data })
            setLoad(true);
            Api.post("/Acesso/Cadastro", data)
              .then((res) => {
                setLoad(false);
                navigate("/login");
                toast({
                  title: "Cadastrado com sucesso!",
                  description:
                    "Verifique em sua caixa de email ou spam e confirme a ativação da conta.",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
              })
              .catch((err) => {
                setLoad(false);
                Verbose({ "/Acesso/Cadastro(catch)": err.response });
                if (
                  err.response.data === "The request field is required..\nThe JSON value could not be converted to System.DateTime. Path: $.dataNascimento | LineNumber: 0 | BytePositionInLine: 317.."
                ) {
                  toast({
                    title: "Senha incompleta",
                    description:
                      "As senhas devem ter pelo menos um caractere não alfanumérico.\nAs senhas devem ter pelo menos um dígito ('0'-'9').",
                    duration: 5000,
                    isClosable: true,
                  });
                }
                if (err.response.data === "Passwords must have at least one non alphanumeric character.\nPasswords must have at least one digit ('0'-'9').\nPasswords must have at least one uppercase ('A'-'Z').") {
                  toast({
                    title: "Senha inválida",
                    description: "As senhas devem ter pelo menos um caractere não alfanumérico.\nAs senhas devem ter pelo menos um dígito ('0'-'9').",
                    status: "warning",
                    duration: 5000,
                    isClosable: true
                  })
                }
                if (err.response.data === "Passwords must have at least one non alphanumeric character.") {
                  toast({
                    title: "Senha inválida",
                    description: "As senhas devem ter pelo menos um caractere não alfanumérico.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true
                  })
                }

                if (err.response.data === `Username '${emailRef.current?.value}' is already taken.`) {
                  toast({
                    title: "Email já existente",
                    description: `O email '${emailRef.current?.value}' já foi usado.`,
                    status: "warning",
                    duration: 5000,
                    isClosable: true
                  })
                }

                if (err.response.data === `Passwords must have at least one digit ('0'-'9').`) {
                  toast({
                    title: "A senha deve conter pelo menos um número",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                  })
                }

              });
          } else {
            toast({
              title: "Data de nascimento inválido.",
              status: 'error',
              duration: 5000,
              isClosable: true
            })
          }

        } else {
          toast({
            title: "É necessário que preenche o seu nome completo.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Senha e redefinir senha não coincidem.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Preenche todos os campos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <Grid templateColumns={["1fr", "1fr", "1fr", "1.5fr 1fr"]}>
      <GridItem
        paddingEnd={["0", "0", "2em"]}
        colSpan={1}
        overflowY="auto"
        bg="white"
      >
        <Box height="100vh" p="6rem" flexDirection="column">
          <Box
            marginBottom="5em"
            display="flex"
            justifyContent={["center", "center", "start"]}
          >
            <Image src={Logo} w="240px" alt="A logo da criterio investimento" />
          </Box>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel htmlFor="nome">Nome completo</FormLabel>
              <Input id="nome" ref={nomeCompletoRef} type="text" />
            </FormControl>
            <HStack
              flexDirection={["column", "column", "row"]}
              spacing={[0, 0, 4]}
            >
              <FormControl flex={2}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="email" ref={emailRef} type="email" />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel htmlFor="cpf">CPF</FormLabel>
                <Input
                  as={InputMask}
                  mask="999.999.999-99"
                  id="cpf"
                  ref={cpfRef}
                  type="text"
                />
              </FormControl>
            </HStack>
            <HStack flexDirection={["column", "row"]}>
              <FormControl flex={1}>
                <FormLabel htmlFor="dataNascimento">
                  Data de Nascimento
                </FormLabel>
                <Input
                  id="dataNascimento"
                  ref={dataNascimentoRef}
                  as={InputMask}
                  mask="99/99/9999"
                />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel htmlFor="telefone">Telefone</FormLabel>
                <Input
                  as={InputMask}
                  mask="(99) 99999-9999"
                  id="telefone"
                  ref={telefoneRef}
                  type="text"
                />
              </FormControl>
            </HStack>

            {/* <FormControl flex={1}>
              <FormLabel htmlFor="subconta">Código subconta</FormLabel>
              <Input id="subconta" ref={subContaRef} type="number" />
            </FormControl> */}

            <Divider />

            <FormControl flex={1}>
              <FormLabel htmlFor="cep">CEP</FormLabel>
              <InputGroup>
                <Input
                  id="cep"
                  as={InputMask}
                  mask="99999999"
                  ref={cepRef}
                  type="text"
                />
                <InputRightElement>
                  <Button variant="primary" isLoading={loadCEP} onClick={() => findEnderecoToCEP()}>
                    <Icon as={MdSearch} />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <HStack flexDirection={["column", "row"]} spacing={[0, 4]}>
              <FormControl flex={2}>
                <FormLabel htmlFor="logradouro">Logradouro</FormLabel>
                <Input id="logradouro" ref={logradouroRef} type="text" />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel htmlFor="numero">Número</FormLabel>
                <Input id="numero" ref={numeroRef} type="number" />
              </FormControl>
            </HStack>
            <HStack flexDirection={["column", "row"]} spacing={[0, 4]}>
              <FormControl flex={2}>
                <FormLabel htmlFor="complemento">Complemento <small>- Optional</small></FormLabel>
                <Input id="complemento" ref={complementoRef} type="text" />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel htmlFor="bairro">Bairro</FormLabel>
                <Input id="bairro" ref={bairroRef} type="text" />
              </FormControl>
            </HStack>
            <HStack flexDirection={["column", "row"]} spacing={[0, 4]}>
              <FormControl flex={2}>
                <FormLabel htmlFor="cidade">Cidade</FormLabel>
                <Input id="cidade" ref={cidadeRef} type="text" />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel htmlFor="uf">uf</FormLabel>
                <Input
                  maxW={["100%", "60px"]}
                  id="uf"
                  ref={ufRef}
                  type="text"
                  as={InputMask}
                  mask="aa"
                />
              </FormControl>
            </HStack>

            <Divider />

            <FormControl flex={1}>
              <FormLabel htmlFor="senha">Senha</FormLabel>
              <InputPassword ref={senhaRef} id="senha" />
            </FormControl>

            <FormControl flex={1}>
              <FormLabel htmlFor="confirmarSenha">Confirmar Senha</FormLabel>
              <InputPassword ref={confirmarSenhaRef} id="confirmarSenha" />
            </FormControl>
            <Box marginBottom='20px'>
              <Text>Sua senha deve cumprir com os seguintes requisitos:</Text>
              <UnorderedList>
                <ListItem>A senha deve conter pelo menos 8 caracteres.</ListItem>
                <ListItem>Utilize letras maiúsculas e minúsculas.</ListItem>
                <ListItem>Incluir pelo menos um caractere especial: @#/?!;*.,</ListItem>
                <ListItem>Incluir pelo menos um número.</ListItem>
              </UnorderedList>
            </Box>
            <HStack spacing={4} pb="5rem" justifyContent={["space-around", "flex-end"]}>
              <Button onClick={() => navigate("/login")}>Voltar</Button>
              <Button
                onClick={() => registrar()}
                variant="primary"
                isLoading={load}
              >
                Registrar
              </Button>
            </HStack>
          </Stack>
        </Box>
      </GridItem>
      <GridItem
        position={["absolute", "absolute", "absolute", "unset"]}
        top="-150%"
        colSpan={1}
      >
        <Center w="100%" h="100vh" bg="primary.100">
          <Image
            boxSize={"100%"}
            objectFit="cover"
            w="100%"
            alt="model-woman"
            src={logoImage}
          />
        </Center>
      </GridItem>
    </Grid>
  );
};

export default Registrar;
