import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { Verbose } from '../../service/Verbose';

const MessageImportToast: React.FC = () => {
    const toast = useToast();
    const [connect, setConnect] = useState<HubConnection | null>(null);

    useEffect(() => {
        const winLocate = window.location.hostname === "localhost" ?
            'https://localhost:7048/ws/notification' :
            '/ws/notification';
        Verbose({ winLocate });
        const connectInit = new HubConnectionBuilder()
            // .withUrl('https://criteriocapital.henrystore.com.br/ws/notification')
            .withUrl(winLocate)
            .withAutomaticReconnect()
            .build();
        Verbose({ "initConnect": connectInit })
        setConnect(connectInit);
    }, [])

    useEffect(() => {
        if (connect) {
            connect.start()
                .then(() => {
                    connect.on("Notification", (mensagem) => {
                        toast.closeAll();
                        toast({
                            variant: "left-accent",
                            position: 'bottom-left',
                            status: "info",
                            duration: 3000,
                            title: mensagem
                        })
                    })

                })
                .catch(err => {
                    Verbose({ "ws/notification/Notification(catch)": err })
                })
        }

    }, [connect, toast])

    return <></>;
}

export default MessageImportToast;