import { Box, Center, Spinner, Text, Container } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Api from '../api/Api';
import { Verbose } from '../service/Verbose';

type transacoes = {
    data: string
    valor: string
}

type saldoEmpresaType = {
    transacoes: transacoes[],
    saldoAtual: string
}

const SaldoEmpresa: React.FC = () => {
    const [saldo, setSaldo] = useState<saldoEmpresaType>()
    const [load, setLoad] = useState<boolean>(false)
    useEffect(() => {
        setLoad(true);
        Api.get("/Saldo/SaldoEmpresa")
            .then(res => {
                setLoad(false);
                setSaldo(res.data)
            })
            .catch(err => {
                setLoad(false);
                Verbose({ "/Saldo/SaldoEmpresa": err.response.data })
            })
    }, [])


    const transacoesColumn: TableColumn<transacoes>[] = [
        {
            name: "Valor",
            selector: (row) => row.valor,
        },
        {
            name: "Data",
            selector: (row) => row.data,
            cell: (row) => moment(row.data).format("DD/MM/YYYY")
        }
    ]

    return (
        <>
            <Container maxW="5xl">
                <Center flexDirection="column" mb="4">
                    <Text fontSize="1.4rem">Saldo da empresa:</Text>
                    <Text fontWeight="bold" fontSize="2rem">{saldo?.saldoAtual}</Text>
                </Center>
                <Box bg='white.100' p='8' borderRadius="16px" boxShadow="0px  8px 28px #00000006" >
                    <DataTable
                        columns={transacoesColumn}
                        data={saldo?.transacoes ? saldo.transacoes : []}
                        progressPending={load}
                        highlightOnHover
                        fixedHeader
                        fixedHeaderScrollHeight='50vh'
                        customStyles={{
                            cells: {
                                style: {
                                    fontSize: "10px",
                                    fontWeight: "bold"
                                }
                            }
                        }}
                        noDataComponent={
                            <Center p="8">
                                <Text>Nenhuma transação encontrado.</Text>
                            </Center>
                        }
                        progressComponent={
                            <Center p="20">
                                <Spinner
                                    w="80px"
                                    h="80px"
                                    color="primary.100"
                                    thickness='8px'
                                />
                            </Center>
                        }
                    />
                </Box>
            </Container>
        </>
    );
}

export default SaldoEmpresa;