import { Box, Button, Center, Flex, Icon, Spinner, Text, useTheme } from '@chakra-ui/react';
import React, { useContext } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BiExpand } from 'react-icons/bi';
import { FiMinimize } from 'react-icons/fi';
import { SizeContext } from '../../pages/Main/Operacoes';
import { DayTrade } from '../../types/OperaroesTypes';

type ListaDayTradeType = {
    visible: boolean
    load: boolean
    lista: DayTrade[]
}

const ListaDayTrade: React.FC<ListaDayTradeType> = (prop) => {
    const { isSize, toggleSize } = useContext(SizeContext)
    const theme = useTheme();
    const column: TableColumn<DayTrade>[] = [
        {
            name: "Dia",
            selector: (e) => e.dia,
            width: "48px",
            sortable: true,
            compact: true
        },
        {
            name: "Vol. Compra",
            selector: (e) => e.volCompra,
            sortable: true,
            compact: true
        },
        {
            name: "Vol. Venda",
            selector: (e) => e.volVenda,
            sortable: true,
            compact: true
        },
        {
            name: "Vol Total",
            selector: (e) => e.volTotal,
            sortable: true,
            compact: true,
            style: {
                borderRight: `3px solid ${theme.colors.secondary["100"]}`
            }
        },
        {
            name: "Emol. B3",
            selector: (e) => e.emolumento,
            sortable: true,
            compact: true,
            style: {
                borderRight: `3px solid ${theme.colors.secondary["100"]}`
            }
        },
        {
            name: "Corret. B3",
            selector: (e) => e.corretagem,
            sortable: true,
            compact: true
        },
        {
            name: "Corret. Fixa",
            selector: (e) => e.corretagemFixa,
            sortable: true,
            compact: true,
            style: {
                borderRight: `3px solid ${theme.colors.secondary["100"]}`
            }
        },
        {
            name: "Bruto B3",
            selector: (e) => e.bruto,
            sortable: true,
            compact: true
        },
        {
            name: "Custo Operação",
            selector: (e) => e.custo,
            sortable: true,
            compact: true
        },
        {
            name: "Fatura",
            selector: (e) => e.saldo,
            sortable: true,
            compact: true
        }
    ]

    return (
        <Box display={prop.visible ? "block" : "none"}>
            <Flex justifyContent="space-between" alignItems="center">
                <Text pb="4" pt="4" fontWeight="bold" fontSize="1.4rem">Movimentações DayTrade</Text>
                <Button
                    variant="primary-outline"
                    onClick={() => toggleSize()}
                >
                    <Icon as={isSize ? FiMinimize : BiExpand} />
                </Button>
            </Flex>
            <Box bg='white.100' p='8' borderRadius="16px" boxShadow="0px  8px 28px #00000006" >
                <DataTable
                    columns={column}
                    data={prop.lista}
                    fixedHeader
                    fixedHeaderScrollHeight='50vh'
                    highlightOnHover
                    customStyles={{
                        cells: {
                            style: {
                                fontSize: "10px",
                                fontWeight: "bold"
                            }
                        }
                    }}
                    progressPending={prop.load}
                    noDataComponent={
                        <Center p="8">
                            <Text>Nenhum DayTrade encontrado.</Text>
                        </Center>
                    }
                    progressComponent={
                        <Center p="20">
                            <Spinner
                                w="80px"
                                h="80px"
                                color="primary.100"
                                thickness='8px'
                            />
                        </Center>
                    }
                />
            </Box>
        </Box>
    );
}

export default ListaDayTrade;