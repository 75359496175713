import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Image,
  SimpleGrid,
  Input,
  FormLabel,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Button, useToast } from "@chakra-ui/react";
import Api from "../../api/Api";
import Logo from "../../assets/logo.png";
import ContanierImageAuth from "../../components/ContanierImageAuth/ContanierImageAuth";
import { Verbose } from "../../service/Verbose";
import InputPassword from "../../components/InputPassword/Index";
import { useRef } from "react";

const RedefinirSenha: React.FC = () => {
  const [load, setLoad] = useState(false);
  const novaSenhaRef = useRef<HTMLInputElement>(null);
  const confirmarSenhaRef = useRef<HTMLInputElement>(null);
  const redefinirSenhaBtnRef = useRef<HTMLButtonElement>(null);
  // const [novaSenha, setNovaSenha] = useState("");
  // const [confirmarSenha, setConfirmarSenha] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const email = query.get("email");
  const token = query.get("token");

  function redefinirSenha() {
    const data = {
      code: token,
      email: email,
      senha: novaSenhaRef.current?.value
    };
    if (novaSenhaRef.current?.value !== "" && confirmarSenhaRef.current?.value !== "") {
      if (novaSenhaRef.current?.value === confirmarSenhaRef.current?.value) {
        setLoad(true);
        Verbose({ "/Acesso/ResetarSenha(request)": data });
        Api.post("/Acesso/ResetarSenha", data)
          .then((res) => {
            navigate("/Login");
            toast({
              title: "Senha redefinida com sucesso!",
              duration: 4000,
              status: "success",
              isClosable: true,
            });
            setLoad(false);
          })
          .catch((err) => {
            setLoad(false);
            Verbose({ "/Acesso/ResetarSenha(catch)": err.response });
            if (err.response.data === "Passwords must have at least one non alphanumeric character.\nPasswords must have at least one digit ('0'-'9').") {
              toast({
                title: "Senha inválida",
                description: "As senhas devem ter pelo menos um caractere não alfanumérico.\nAs senhas devem ter pelo menos um dígito ('0'-'9').",
                status: "warning",
                duration: 6000,
                isClosable: true
              })
            }
            if (err.response.data === "Passwords must have at least one non alphanumeric character.") {
              toast({
                title: "Senha inválida",
                description: "As senhas devem ter pelo menos um caractere não alfanumérico.",
                status: "warning",
                duration: 6000,
                isClosable: true
              })
            }

            if(err.response.data === `Passwords must have at least one digit ('0'-'9').`) {
              toast({
                title: "A senha deve conter pelo menos um número.",
                status: "error",
                duration: 5000,
                isClosable: true
              })
            }

          });

          
      } else {
        setLoad(false);
        toast({
          title: "Senha e Confirmar senha não coincidem.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Preenche todos os campos.",
        status: "error",
        duration: 4000,
        isClosable: true
      })
    }

  }
  return (
    <>
      <SimpleGrid columns={2} display={{ md: "flex" }}>
        <Box
          bg="white"
          width={["100vw", "100vw", "50vw", "40vw"]}
          height="100vh"
          padding="5rem"
          flexDirection="column"
          flexShrink={0}
        >
          <Box
            marginBottom="4em"
            display="flex"
            justifyContent={["center", "center", "start"]}
          >
            <Image src={Logo} w="240px" alt="A logo da criterio investimento" />
          </Box>

          <Box alignItems="center">
            <Box>
              <Box display="flex-row" justifyContent="space-between">
                <Box marginBottom="20px">
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    _placeholder={{ color: "#fff3" }}
                    size="lg"
                    value={email ? email : ""}
                    disabled

                  />
                </Box>

                <Box marginBottom="20px">
                  <FormLabel htmlFor="novaSenha">Nova Senha</FormLabel>
                  <InputPassword
                    ref={novaSenhaRef}
                    id="novaSenha"
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        confirmarSenhaRef.current?.focus()
                    }}

                  />
                </Box>

                <Box>
                  <FormLabel htmlFor="confirmarSenha">Confirmar Senha</FormLabel>
                  <InputPassword
                    ref={confirmarSenhaRef}
                    id="confirmarSenha"
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        redefinirSenhaBtnRef.current?.click()
                    }}
                  />
                </Box>
                <Box marginBottom='20px'>
                  <Text>Sua senha deve cumprir com os seguintes requisitos:</Text>
                  <UnorderedList>
                    <ListItem>A senha deve conter pelo menos 8 caracteres.</ListItem>
                    <ListItem>Utilize letras maiúsculas e minúsculas.</ListItem>
                    <ListItem>Incluir pelo menos um caractere especial: @#/?!;*.,</ListItem>
                    <ListItem>Incluir pelo menos um número.</ListItem>
                  </UnorderedList>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={["column", "column", "row-reverse"]}
              marginTop="2em"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                marginBottom={["1.6em", "1.6em", "0"]}
                width={["100%", "100%", "auto"]}
                variant="primary"
                size="lg"
                ref={redefinirSenhaBtnRef}
                isLoading={load}
                onClick={() => redefinirSenha()}
              >
                Redefinir Senha
              </Button>
              <Button
                width={["100%", "100%", "auto"]}
                size="lg"
                onClick={() => navigate("/Login", { replace: true })}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
        <ContanierImageAuth />
        {/* <Center
          bg="darkBlue.100"
          w="100%"
          h="100vh"
          p="8"
          position={["absolute", "absolute", "unset"]}
          top="-150%"
        >
          <Image src={loginIlustration} />
        </Center> */}
      </SimpleGrid>
    </>
  );
};

export default RedefinirSenha;
