import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Link } from "@chakra-ui/react";
import { FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";
import Api from "../../api/Api";

import Logo from "../../assets/logo.png";
import { RolesContext } from "../../App";

import ContanierImageAuth from "../../components/ContanierImageAuth/ContanierImageAuth";
import { Verbose } from "../../service/Verbose";
import InputPassword from "../../components/InputPassword/Index";


const Login: React.FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const senhaRef = useRef<HTMLInputElement>(null);
  const entrarRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const { setRoles } = useContext(RolesContext);
  function entrar() {
    setLoad(true);
    const data = {
      login: emailRef.current?.value,
      senha: senhaRef.current?.value,
    };
    if (emailRef.current?.value !== "" || senhaRef.current?.value !== "") {
      Api.post("/Acesso/Login", data)
        .then((res) => {
          localStorage.setItem("@CI_TOKEN", res.data.token);
          localStorage.setItem("@CI_EXP", res.data.expiration);
          setRoles(res.data.roles);
          Api.defaults.headers.common["Authorization"] = res.data.token;
          setLoad(false);
          navigate("/");
        })
        .catch((err) => {
          setLoad(false);

          if (err.response.data === "EMAIL_NAO_CONFIRMADO") {
            toast({
              title: "Email não confirmado",
              description: "Enviamos em sua caixa de email a confirmação da sua conta.",
              status: "warning",
              isClosable: false,
            });
          } else {
            toast({
              title: "Email ou senha inválido",
              description: "O email não consta no nosso banco de dados OU a senha está incorreta.",
              status: "error",
              isClosable: false,
            });
          }

          Verbose({ "/Acesso/Login(catch)": err.response });
        });
    } else {
      setLoad(false);
      toast({
        title: "Campos vazios",
        description: 'Por favor, preencha todos os campos.',
        duration: 4000,
        isClosable: true,
        status: "error",
      });
    }
  }

  useEffect(() => {
    localStorage.clear();
  }, [])

  return (
    <SimpleGrid columns={[2]} display={{ md: "flex" }}>
      <Box
        bg="white"
        width={["100vw", "100vw", "60vw", "50vw", "40vw"]}
        height="100vh"
        padding="5rem"
        flexDirection="column"
        flexShrink={0}
      >
        <Box
          marginBottom="5em"
          display='flex'
          justifyContent={['center', 'center', 'start']}
        >
          <Image src={Logo} w="240px" alt="A logo da criterio investimento" />
        </Box>
        <Box>
          <FormControl color="black">
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              ref={emailRef}
              size="lg"
              id="email"
              type="email"
              onKeyDown={(e) => {
                if(e.key === "Enter")
                  senhaRef.current?.focus()
              }}
              borderColor="gray"
            />
            <FormLabel marginTop="25px" htmlFor="senha">
              Senha
            </FormLabel>
            <InputPassword
              id="senha"
              ref={senhaRef}
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  entrarRef.current?.click()
              }}
            />

          </FormControl>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            marginTop="2em"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems={["center", "center", "start"]}
              marginBottom="2em"
            >
              <Link
                onClick={() => navigate("/EsqueciSenha")}
                marginBottom={["1.6em", "1.6em", "0"]}
              >
                Esqueci minha senha
              </Link>
              <Link onClick={() => navigate("/registrar")}>
                Não possui uma conta?{" "}
                <Text as="span" color="primary.100">
                  Cadastre-se
                </Text>
              </Link>
            </Box>

            <Button
              onClick={() => entrar()}
              width={["100%", "100%", "auto"]}
              variant="primary"
              size="lg"
              isLoading={load}
              ref={entrarRef}
            >
              Entrar
            </Button>
          </Box>

          <Box pt="8"></Box>
        </Box>
      </Box>
      {/* <Center
        bg="darkBlue.100"
        h="100vh"
        w="100vw"
        p="8"
        position={["absolute", "absolute", "unset"]}
        top="-150%"
      >
        <Image src={loginIlustration} />
      </Center> */}

      <ContanierImageAuth />
    </SimpleGrid>
  );
};

export default Login;
