import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { InputRightElement, Heading, Flex, Input, InputGroup, Icon, Button, Stack, Center, ButtonGroup, useToast, Container } from '@chakra-ui/react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import InputMask from 'react-input-mask'
import { MdDateRange } from 'react-icons/md'
import moment from 'moment';
import ListaExtrato from '../../components/ListaOperacoes/Extrato';
import ModalImportarCSV from '../../components/ModalImportarCSV/Index';
import ListaDayTrade from '../../components/ListaOperacoes/DayTrade';
import ListaBuyHold from '../../components/ListaOperacoes/BuyHold';
import { RolesContext } from '../../App';
import Api from '../../api/Api';
import { BMF, BuyHold, DayTrade, extrato } from '../../types/OperaroesTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import ListaBMF from '../../components/ListaOperacoes/ListaBMF';
import { Verbose } from '../../service/Verbose';

type operacoesLista = "Extrato" | "DayTrade" | "BuyHold" | "BMF";
type responseMovimentacoes = { extratos: extrato[], dayTrades: DayTrade[], buyHolds: BuyHold[], bmfs: BMF[] }
type movimentacaoStatePage = { email?: string, nome?: string }

type sizeContextType = { isSize: boolean, toggleSize: () => void }
export const SizeContext = createContext<sizeContextType>({
  isSize: false,
  toggleSize: () => null
});

const Operacoes: React.FC = () => {
  const [openImportar, setOpenImportar] = useState<boolean>(false)
  const [data, setData] = useState<string>(moment().format("MM/YYYY").toString())
  const [info, setInfo] = useState<responseMovimentacoes>();
  const [expand, setExpand] = useState<boolean>(false);
  // const pesquisaRef = useRef<HTMLInputElement>(null);
  const [load, setLoad] = useState<boolean>(false);
  const [operacaoTipo, setOperacaoTipo] = useState<operacoesLista>("Extrato");
  const { roles } = useContext(RolesContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email, nome } = state ? state as movimentacaoStatePage : { email: "", nome: "" };

  const getMovimentacao = useCallback(() => {
    setInfo({
      bmfs: [],
      buyHolds: [],
      dayTrades: [],
      extratos: []
    })
    const isEmail = (email !== "" && email !== undefined) ? email : "";
    const routeEmail = isEmail === "" ? `/Movimentacao/VisualizarMovimentacoes/trader?mes=${data}` :
      `/Movimentacao/VisualizarMovimentacoes?Mes=${data}&Email=${isEmail}`;
    if (data !== "") {
      setLoad(true);
      Verbose(routeEmail);
      Api.get<responseMovimentacoes>(routeEmail)
        .then(res => {
          Verbose({ [`${routeEmail}(response)`]: res.data })
          setInfo(res.data)
          setLoad(false)
        })
        .catch(err => {
          Verbose({ [`${routeEmail}(catch)`]: err.response });
          setLoad(false)
        })
    } else {
      toast({
        title: "Preenche a data",
        status: "error",
        duration: 2000,
      })
    }
  }, [data, email, toast])

  useEffect(() => {
    const regData = new RegExp(/^\d{2}[/]\d{4}$/g);
    const verifyData = regData.test(data);
    if (verifyData) {
      if (email !== "" && email !== undefined) {
        if (roles.find(e => e === "ADMINISTRADOR")) {
          getMovimentacao();
        } else { navigate("/usuarios") }
      } else { getMovimentacao(); }
    }
    //   if (roles.find(e => e === "ADMINISTRADOR")) {
    //     if (email !== "" && email !== undefined) {
    //       getMovimentacao();
    //     } else { navigate('/usuarios') }
    //   }

    //   if (roles.find(e => e === "TRADER") && !roles.find(e => e === "ADMINISTRADOR"))
    //     getMovimentacao();
    // }
  }, [email, roles, data, navigate, getMovimentacao])


  return (
    <>
      <Container maxW="5xl">

        {roles.find(e => e === "ADMINISTRADOR") && (
          <Button
            onClick={() => navigate("/usuarios")}
            variant="primary"
            size="sm"
            leftIcon={<Icon as={AiOutlineArrowLeft} color="white" />}
          >
            Voltar
          </Button>
        )}

        <Flex
          alignItems="center"
          justifyContent="space-between"
          pb="8"
        >
          <Heading size="lg" pr="4">
            Operações {nome ? `de ${nome}` : ""}
          </Heading>
          <Stack direction="row" spacing={4} w='100'>
            <InputGroup w="320px" >
              <Input
                value={data}
                onChange={(te) => setData(te.target.value)}
                placeholder="Data..."
                as={InputMask}
                mask="99/9999"
              />
              <InputRightElement>
                <Button variant="primary"
                  isLoading={load}
                  onClick={() => getMovimentacao()} size="md" w="2.5em" >
                  <Icon as={MdDateRange} />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Stack>
        </Flex>
        <ModalImportarCSV open={openImportar} onClose={() => setOpenImportar(false)} />

        <Center justifyContent="flex-end">
          <ButtonGroup isAttached variant="primary-group">
            <Button bg={operacaoTipo === "Extrato" ? "primary.100" : "primary.50"} onClick={() => setOperacaoTipo("Extrato")}  >
              Extrato
            </Button>
            <Button bg={operacaoTipo === "DayTrade" ? "primary.100" : "primary.50"} onClick={() => setOperacaoTipo("DayTrade")}  >
              DayTrade
            </Button>
            <Button bg={operacaoTipo === "BuyHold" ? "primary.100" : "primary.50"} onClick={() => setOperacaoTipo("BuyHold")}  >
              BuyHold
            </Button>
            <Button bg={operacaoTipo === "BMF" ? "primary.100" : "primary.50"} onClick={() => setOperacaoTipo("BMF")}  >
              BMF
            </Button>
          </ButtonGroup>
        </Center>
      </Container>

      <Container maxW={expand ? "none" : "5xl"}>
        <SizeContext.Provider value={{ isSize: expand, toggleSize: () => setExpand(!expand) }}>
          <ListaExtrato load={load} lista={info?.extratos ? info.extratos : []} visible={operacaoTipo === "Extrato"} />
          <ListaDayTrade lista={info?.dayTrades ? info.dayTrades : []} load={load} visible={operacaoTipo === "DayTrade"} />
          <ListaBuyHold lista={info?.buyHolds ? info.buyHolds : []} load={load} visible={operacaoTipo === "BuyHold"} />
          <ListaBMF lista={info?.bmfs ? info.bmfs : []} load={load} visible={operacaoTipo === "BMF"} />
        </SizeContext.Provider>
      </Container>
    </>
  );
}

export default Operacoes;