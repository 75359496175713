import { Box, Button, Center, Container, Flex, Heading, HStack, Icon, Menu, MenuButton, MenuList, Spinner, Text } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import DataTable, { TableColumn } from 'react-data-table-component';
import Api from '../../api/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import { CgMenu } from 'react-icons/cg'
import { CustodiaType } from '../../types/CustodiaType';
import { RolesContext } from '../../App';
import ModalCadastrarCustodia from '../../components/ModalCustodia/ModalCadastrarCustodia';
import ModalEditarCustodia from '../../components/ModalCustodia/ModalEditarCustodia';
import ModalRemoverCustodia from '../../components/ModalCustodia/ModalRemoverCustodia';
import { Verbose } from '../../service/Verbose';

type custodiaState = { idUsuario: string, nome: string }
const Custodia: React.FC = () => {
    const { roles } = useContext(RolesContext);
    const navigate = useNavigate();
    const [load, setLoad] = useState<boolean>(true)
    const [custodias, setCustodias] = useState<CustodiaType[]>([]);
    const { state } = useLocation();
    const { idUsuario, nome } = state ? state as custodiaState : { idUsuario: "", nome: "" }
    const [openCadastrarCustodia, setOpenCadastrarCustodia] = useState<boolean>(false);
    const [reload, setReload] = useState<number>(0)
    const columns: TableColumn<CustodiaType>[] = [
        {
            name: "Ativo",
            selector: (e) => e.ativo,
            compact: true
        },
        {
            name: "Qtd Líquida",
            selector: (e) => e.qtdLiquida,
            compact: true
        },
        {
            name: "Compra média",
            selector: (e) => e.compraMedia,
            compact: true
        },
        {
            name: "Valor retido",
            selector: (e) => e.valorRetido,
            compact: true
        },
        {
            name: "Venda media",
            selector: (e) => e.vendaMedia,
            compact: true
        },
        {
            name: "Data",
            selector: (e) => e.data.toString(),
            compact: true
        },
        {
            name: "Ações",
            omit: !roles.find(e => e === "ADMINISTRADOR"),
            cell: (row) => (
                <Menu>
                    <MenuButton>
                        <Icon as={CgMenu} color="primary.100" h="18px" />
                    </MenuButton>
                    <MenuList>
                        <ModalEditarCustodia idCustodia={row.id} reloadList={() => setReload(e => e + 1)} />
                        <ModalRemoverCustodia idCustodia={row.id} reloadList={() => setReload(e => e + 1)} />
                    </MenuList>
                </Menu>
            )
        }
    ]

    const getCustodias = useCallback(() => {
        const isIdUsuario = (idUsuario !== "" && idUsuario !== undefined) ? idUsuario : "";
        const route = isIdUsuario === "" ? "/Custodia/Lista/Usuario" : `/Custodia/Lista/${idUsuario}`;

        Api.get<CustodiaType[]>(route)
            .then(res => {
                Verbose({ [route]: res.data })
                setCustodias(res.data);
                setLoad(false);
            })
            .catch(err => {
                Verbose({ [route]: err.response });
                setLoad(false);
            })
    }, [idUsuario])

    useEffect(() => {
        if (idUsuario !== "" && idUsuario !== undefined) {
            if (roles.find(e => e === "ADMINISTRADOR")) {
                getCustodias();
            } else { navigate("/operacoes") }
        } else { getCustodias() }

        // if (roles.find(e => e === "ADMINISTRADOR")) {
        //     if (idUsuario !== "" && idUsuario !== undefined) {
        //         getCustodias();
        //     } else {
        //         navigate('/usuarios');
        //     }
        // }
        // if (!roles.find(e => e === "ADMINISTRADOR") && roles.find(e => e === "TRADER"))
        //     getCustodias();

        // if (roles.length === 1 && roles.find(e => e === "CLIENTE")) {
        //     navigate(-1);
        // }

    }, [idUsuario, roles, navigate, reload, getCustodias])

    return (
        <Container maxW="5xl">
            {roles.find(e => e === "ADMINISTRADOR") && (
                <Button
                    onClick={() => navigate("/usuarios")}
                    variant="primary"
                    size="sm"
                    mb="4"
                    leftIcon={<Icon as={AiOutlineArrowLeft} color="white" />}
                >
                    Voltar
                </Button>
            )}
            <Flex
                alignItems="center"
                justifyContent="space-between"
                pb="8"
            >
                <Heading size="lg" pr="4">
                    Custódia {(nome !== "" && nome !== undefined) && `de ${nome}`}
                </Heading>
                <HStack spacing={4}>
                    {
                        roles.find(e => e === "ADMINISTRADOR") && (
                            <Button variant="primary" onClick={() => setOpenCadastrarCustodia(true)}>
                                Cadastrar nova custódia
                            </Button>
                        )
                    }
                </HStack>
            </Flex>
            <Box bg='white.100' p='8' w="100%" borderRadius="16px" boxShadow="0px  8px 28px #00000006" >
                <DataTable
                    highlightOnHover
                    customStyles={{
                        responsiveWrapper: {
                            style: {
                                overflowY: "inherit",
                                overflowX: "inherit"
                            }
                        }
                    }}
                    columns={columns}
                    data={custodias}
                    pagination
                    progressPending={load}
                    progressComponent={
                        <Center p="20">
                            <Spinner
                                w="80px"
                                h="80px"
                                color="primary.100"
                                thickness='8px'
                            />
                        </Center>
                    }
                    noDataComponent={
                        <Center p="3">
                            <Text>Custódias não encontrados.</Text>
                        </Center>
                    }
                    paginationComponentOptions={{
                        rowsPerPageText: "Custódias por página",
                        rangeSeparatorText: "de"
                    }}
                />
            </Box>
            <ModalCadastrarCustodia
                idUsuario={idUsuario}
                open={openCadastrarCustodia}
                onClose={() => setOpenCadastrarCustodia(false)}
                reloadList={() => setReload(e => e + 1)}
            />
        </Container>
    );
}

export default Custodia;