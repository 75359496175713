import { roles } from "../components/ProtectRouteSystem";
import { UsuarioAdminItem } from "../types/UsuarioTypes";

export type UsuarioPerfilUsers = Omit<UsuarioAdminItem, 'perfil'> & { perfil: roles[] }

function ValidateUsuarios(usuarios: UsuarioAdminItem[]): UsuarioPerfilUsers[] {
    let usuarioReturn: UsuarioPerfilUsers[] = [];

    for (let i = 0; i < usuarios.length; i++) {

        const validateUsuario = usuarioReturn.find(e => e.id === usuarios[i].id);
        if (!validateUsuario) {
            const format: UsuarioPerfilUsers = {
                ...usuarios[i],
                perfil: [usuarios[i].perfil]
            }
            usuarioReturn.push(format)
            continue;
        }

        const localeItem = usuarioReturn.indexOf(validateUsuario);
        usuarioReturn[localeItem].perfil.push(usuarios[i].perfil);
    }

    return usuarioReturn;
}

export default ValidateUsuarios;