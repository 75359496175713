import { Button, FormControl, FormLabel, HStack, Input, Stack, useToast } from '@chakra-ui/react';
import moment from 'moment';
import React, { useState } from 'react';
import Api from '../../api/Api';
import CModal from '../CModal/Index';

type ModalCadastrarCustodiaType = {
    open: boolean
    onClose: () => any
    idUsuario: string
    reloadList: () => any
}

const ModalCadastrarCustodia: React.FC<ModalCadastrarCustodiaType> = (prop) => {
    const [subtitular, setSubtitular] = useState<string>("")
    const [ativo, setAtivo] = useState<string>("")
    const [qtdLiquida, setQtdLiquida] = useState<string>("")
    const [compraMedia, setCompraMedia] = useState<string>("")
    const [vendaMedia, setVendaMedia] = useState<string>("")
    const [data, setData] = useState<string>(moment().format('YYYY-MM-DD'))
    //
    const [load, setLoad] = useState<boolean>(false)
    const toast = useToast();
    function cadastrarCustodia() {
        const request = {
            idUsuario: prop.idUsuario,
            subtitular,
            ativo,
            qtdLiquida,
            compraMedia,
            vendaMedia,
            data
        }
        if (
            subtitular !== "" &&
            ativo !== "" &&
            qtdLiquida !== "" &&
            compraMedia !== "" &&
            vendaMedia !== "" &&
            data !== ""
        ) {
            console.log({ "/custodia(post)(request)": request });
            setLoad(true)
            Api.post('/custodia', request)
                .then(() => {
                    setLoad(false)
                    prop.onClose()
                    toast({
                        title: "Custódia cadastrado com sucesso!",
                        duration: 4000,
                        isClosable: true,
                        status: "success"
                    })
                    prop.reloadList()
                })
                .catch(err => {
                    setLoad(false)
                    console.log({ "/custodia(post)(catch)": err.response })
                })
        } else {
            toast({
                title: "Preenche todos os campos",
                status: "error",
                duration: 3000
            })
        }

    }

    return (
        <CModal
            title="Cadastrar Custódia"
            onClose={prop.onClose}
            open={prop.open}
            footer={
                <HStack spacing={4}>
                    <Button onClick={prop.onClose}>
                        Cancelar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => cadastrarCustodia()}
                        isLoading={load}
                    >
                        Cadastrar
                    </Button>
                </HStack>
            }
        >
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel htmlFor="subtitular">SubTitular</FormLabel>
                    <Input id="subtitular" value={subtitular} onChange={(e) => setSubtitular(e.target.value)} type="text" />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="ativo">Ativo</FormLabel>
                    <Input id="ativo" value={ativo} onChange={(e) => setAtivo(e.target.value)} type="text" />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="qtdLiquida">Quantidade Líquida</FormLabel>
                    <Input id="qtdLiquida" value={qtdLiquida} onChange={(e) => setQtdLiquida(e.target.value)} type="number" />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="compraMedia">Compra Média</FormLabel>
                    <Input id="compraMedia" value={compraMedia} onChange={(e) => setCompraMedia(e.target.value)} type="number" />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="vendaMedia">Venda Média</FormLabel>
                    <Input id="vendaMedia" value={vendaMedia} onChange={(e) => setVendaMedia(e.target.value)} type="number" />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="data">Data</FormLabel>
                    <Input id="data" value={data} onChange={(e) => setData(e.target.value)} type="date" />
                </FormControl>
            </Stack>
        </CModal>
    );
}

export default ModalCadastrarCustodia;