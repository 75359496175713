import { Box, Center, Image, Progress } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RolesContext } from '../App';
import logo from '../assets/logo.png';
import { isLogged } from '../service/IsLogged';
import { Verbose } from '../service/Verbose';
const Carregando: React.FC = () => {
    const navigate = useNavigate();
    const { roles } = useContext(RolesContext);
    useEffect(() => {
        const logado: boolean = isLogged();
        Verbose({ logado })
        if (logado) {
            setTimeout(() => {
                navigate('/login')
            }, 2000)
        } else {
            setTimeout(() => {
                if (roles.find(e => e === "ADMINISTRADOR")) {
                    navigate('/usuarios')
                } else {
                    navigate('/operacoes');
                }
            }, 2000)
        }
    }, [navigate, roles])

    return (
        <Center h="100vh" w='100%' flexDirection="column">
            <Box w="300px">
                <Image src={logo} w="100%" pb="20px" />
                <Progress
                    size="xs"
                    isIndeterminate
                    colorScheme="secondary"
                    w="100%"
                />
            </Box>
        </Center>
    );
}

export default Carregando;