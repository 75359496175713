import { extendTheme } from "@chakra-ui/react";
import '@fontsource/montserrat'

const defaultStyle = extendTheme({
    styles: {
        global: () => ({
            body: {
                backgroundColor: "#f8f8f8",
                boxSizing: "border-box",
                margin: 0,
                padding: 0,
                outline: "none",
            },
        }),
    },

    fonts: {
        body: "Montserrat, sans-serif",
        heading: "Montserrat, sans-serif",
        text: "Montserrat, sans-serif, fontWeigth 500"
    },

    colors: {
        primary: {
            "100": "#002D56",
            "80": "#003363",
            "50": "#0054A3",
            "500": "#002D56"
        },
        secondary: {
            "100": "#CB973B",
            "80": "#D9A03F",
            "50": "#E6AA43",
            "500": "#CB973B"
        },
        white: {
            "100": "#fff",
        },
        global: {
            body: {
                padding: 0,
                margin: 0,
                boxSizing: "border-box",
                fontWeigth: "medium"
            },
        },
    },

    components: {
        Text: {
            variants: {
                link: {
                    color: "primary.100",
                    fontWeight: "medium",
                    cursor: "pointer",
                    _hover: {
                        color: "secondary.100",
                    },
                },
            },
        },
        Input: {
            defaultProps: {
                color: 'secondary.100',
                bg: "white.100",
                focusBorderColor: "primary.100",
            },
            baseStyle: {
                bg: "white.100"
            }
        },
        Button: {
            variants: {
                "primary": {
                    bg: "primary.100",
                    color: "white.100",

                    _hover: {
                        bg: "primary.80"
                    },
                    _focus: {
                        bg: "primary.50"
                    },
                    _active: {
                        bg: "primary.50"
                    }
                },
                "secondary": {
                    bg: "secondary.100",
                    color: "white.100",

                    _hover: {
                        bg: "secondary.80"
                    },
                    _focus: {
                        bg: "secondary.50"
                    }
                },
                "primary-group": {
                    bg: "red.100",
                    color: "white.100",

                    _hover: {
                        bg: "primary.100"
                    },
                    _focus: {
                        bg: "primary.100"
                    },
                    _active: {
                        bg: "primary.100"
                    }
                },
                "primary-outline": {
                    bg: "white",
                    color: "primary.100",
                    borderWidth: "1px",
                    borderColor: "primary.50",                    

                    _hover: {
                        bg: "white"
                    },
                    _focus: {
                        bg: "white"
                    },
                    _active: {
                        bg: "white"
                    }
                }
            }
        }
    },
});

export default defaultStyle;
