import React, { ReactChild } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'


type CModalType = {
    title: string
    footer: JSX.Element
    open: boolean
    size?: string
    onClose: () => any
    marginFull?: string
    children: ReactChild
    scrollBehahaivor?: "inside" | "outside"
}

const CModal: React.FC<CModalType> = ({
    children,
    footer,
    open,
    onClose,
    title,
    size,
    scrollBehahaivor = "inside",
    marginFull
}) => {

    return (
        <Modal isOpen={open} onClose={onClose} size={size} scrollBehavior={scrollBehahaivor} >
            <ModalOverlay />
            <ModalContent mx={size === "full" ? marginFull : "0"}>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {children}
                </ModalBody>

                <ModalFooter>
                    {footer}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default CModal;