import { Button, HStack, MenuItem, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import Api from '../../api/Api';
import { Verbose } from '../../service/Verbose';
import CModal from '../CModal/Index';

type ModalRemoverCustodiaType = {
    idCustodia: string
    reloadList: () => any
}

const ModalRemoverCustodia: React.FC<ModalRemoverCustodiaType> = (prop) => {
    const [open, setOpen] = useState<boolean>(false)
    const [load, setLoad] = useState<boolean>(false)
    const toast = useToast();


    function removerCustodia() {
        setLoad(true)
        Api.get(`/custodia/Deletar/${prop.idCustodia}`)
            .then(() => {
                setLoad(false)
                toast({
                    title: "Custódia removida com sucesso",
                    status: "success",
                    duration: 3000,
                    isClosable: true
                });
                setOpen(false)
                prop.reloadList()
            })
            .catch(err => {
                setLoad(false);
                Verbose({ [`/custodia/Deletar/${prop.idCustodia}(catch)`]: err.response });
            })
    }


    return (
        <>
            <MenuItem
                onClick={() => setOpen(true)}>
                Remover
            </MenuItem>

            <CModal
                open={open}
                onClose={() => setOpen(false)}
                title="Remover Custódia"
                footer={
                    <HStack spacing={4}>
                        <Button onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => removerCustodia()} isLoading={load}>
                            Remover custódia
                        </Button>
                    </HStack>
                }
            >
                <>
                    <Text fontSize="18px">
                        Deseja mesmo remover essa Custódia?
                    </Text>
                </>
            </CModal>
        </>
    );
}

export default ModalRemoverCustodia;