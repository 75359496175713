import React from 'react';
import { roles } from '../ProtectRouteSystem';
import { Icon } from '@chakra-ui/react'
import { GrUserAdmin } from 'react-icons/gr';
import { AiOutlineUser } from 'react-icons/ai';
import { FaMoneyBillWaveAlt } from 'react-icons/fa';
import { MdDisabledByDefault, MdManageAccounts } from 'react-icons/md';
import { BiDirections } from 'react-icons/bi';

type RoleIconsType = {
  role: roles,
  w?: number | string
  h?: number | string
  color: string
}

const RoleIcon: React.FC<RoleIconsType> = (prop) => {

  function iconRole(role: roles) {
    switch (role) {
      case "ADMINISTRADOR":
        return GrUserAdmin;
      case "CLIENTE":
        return AiOutlineUser
      case "TRADER":
        return FaMoneyBillWaveAlt
      case "GESTOR":
        return MdManageAccounts
      case "INDICADOR":
        return BiDirections
      default:
        return MdDisabledByDefault
    }
  }

  return (
    <Icon
      as={iconRole(prop.role)}
      w={prop.w}
      h={prop.h}
      color={prop.color}
    />
  )


}

export default RoleIcon;