import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom'
import moment from 'moment'
export type roles = "CLIENTE" | "ADMINISTRADOR" | "TRADER" | "GESTOR" | "INDICADOR"

interface ProtectRouteSystemType {
    roles: roles[]
    children: JSX.Element
}


const ProtectRouteSystem: React.FC<ProtectRouteSystemType> = ({
    roles,
    children
}) => {
    const [valid, setValid] = useState<boolean>(true);
    const [permition, setPermition] = useState<boolean>(true);
    let thread = useRef<NodeJS.Timer>();

    useEffect(() => {
        thread.current = setInterval(() => {
            const token: string | null = localStorage.getItem("@CI_TOKEN");
            const exp: string | null = localStorage.getItem("@CI_EXP");
            const role: string | null = localStorage.getItem("@CI_ROLE");
            if (token === null || role === null)
                setValid(false);

            if (exp === null || moment().diff(exp, "seconds") > 1)
                setValid(false)

            const rolesList: roles[] = JSON.parse(role!);
            let permission: boolean = true;
            roles.forEach(er => {
                const find = rolesList.find(e => e === er);
                if (find) {
                    permission = true
                } else {
                    permission = false
                }
            })
            if (!permission && roles.length !== 0)
                setPermition(false)

        }, 100)
        return () => {
            if (thread.current) {
                clearInterval(thread.current)
            }
        }
    }, [thread, roles])

    return valid ? (permition ? children : <Navigate to='/*' />) : <Navigate to='/login' />
}

export default ProtectRouteSystem;