import { Button, Center, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FcSearch } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom';
const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Center h='100vh' w="100%" textAlign="center" flexDir="column" p="18">
        <Icon as={FcSearch} w="64" h="64" />
        <Text fontSize="32px">Página não encontrada ou você não tem permissão <br /> de acessar essa página.</Text>
        <Button
          variant="primary"
          onClick={() => navigate(-2)}
          mt="4"
        >
          Voltar
        </Button>
      </Center>
    </>
  );
}

export default NotFound;