import React from 'react';
import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import NavbarWrapper from './NavbarWrapper';
import MessageImportToast from '../MessageImportToast/Index';
const DashboardContainer: React.FC = () => {
  return (
    <>
    <MessageImportToast />
    
      <NavbarWrapper />
      <Box pt="8" pb="8" >
        <Outlet />
      </Box>
    </>
  );
}

export default DashboardContainer;