import { Button, FormControl, FormLabel, HStack, Input, MenuItem, Stack, useToast } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Api from '../../api/Api';
import { Verbose } from '../../service/Verbose';
import { CustodiaType } from '../../types/CustodiaType';
import CModal from '../CModal/Index';

type ModalEditarCustodiaType = {
    idCustodia: string,
    reloadList: () => any
}

const ModalEditarCustodia: React.FC<ModalEditarCustodiaType> = (prop) => {
    const [ativo, setAtivo] = useState<string>("")
    const [qtdLiquida, setQtdLiquida] = useState<string>("")
    const [compraMedia, setCompraMedia] = useState<string>("")
    const [vendaMedia, setVendaMedia] = useState<string>("")
    const [data, setData] = useState<string>(moment().format('YYYY-MM-DD'))
    //
    const [open, setOpen] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(false)
    const toast = useToast();
    function editarCustodia() {
        const request = {
            idCustodia: prop.idCustodia,
            ativo,
            qtdLiquida,
            compraMedia,
            vendaMedia,
            data
        }
        if (
            ativo !== "" &&
            qtdLiquida !== "" &&
            compraMedia !== "" &&
            vendaMedia !== "" &&
            data !== ""
        ) {
            Verbose({ "/custodia(post)(request)": request });
            setLoad(true)
            Api.post('/custodia/Editar', request)
                .then(() => {
                    setLoad(false)
                    setOpen(false)
                    toast({
                        title: "Custódia Editado com sucesso!",
                        duration: 4000,
                        isClosable: true,
                        status: "success"
                    })
                    prop.reloadList()
                })
                .catch(err => {
                    setLoad(false)
                    Verbose({ "/custodia(post)(catch)": err.response })
                })
        } else {
            toast({
                title: "Preenche todos os campos",
                status: "error",
                duration: 3000
            })
        }

    }

    useEffect(() => {
        if (open) {
            Api.get<CustodiaType>(`/Custodia/${prop.idCustodia}`)
                .then(res => {
                    Verbose({ [`/Custodia/${prop.idCustodia}(response)`]: res.data });
                    setAtivo(res.data.ativo)
                    setQtdLiquida(res.data.qtdLiquida.toString())
                    setCompraMedia(res.data.compraMedia.toString())
                    setVendaMedia(res.data.vendaMedia.toString())
                    setData(moment(res.data.data).format('YYYY-MM-DD'))
                })
                .catch(err => {
                    Verbose({ [`/Custodia/${prop.idCustodia}(catch)`]: err.response });
                })
        }
    }, [open, prop.idCustodia])

    return (
        <>
            <MenuItem onClick={() => setOpen(true)}>
                Editar
            </MenuItem>
            <CModal
                title="Editar Custódia"
                onClose={() => setOpen(false)}
                open={open}
                footer={
                    <HStack spacing={4}>
                        <Button onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => editarCustodia()}
                            isLoading={load}
                        >
                            Editar Custódia
                        </Button>
                    </HStack>
                }
            >
                <Stack spacing={4}>
                    <FormControl>
                        <FormLabel htmlFor="ativo">Ativo</FormLabel>
                        <Input id="ativo" value={ativo} onChange={(e) => setAtivo(e.target.value)} type="text" />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="qtdLiquida">Quantidade Líquida</FormLabel>
                        <Input id="qtdLiquida" value={qtdLiquida} onChange={(e) => setQtdLiquida(e.target.value)} type="number" />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="compraMedia">Compra Media</FormLabel>
                        <Input id="compraMedia" value={compraMedia} onChange={(e) => setCompraMedia(e.target.value)} type="number" />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="vendaMedia">Venda Media</FormLabel>
                        <Input id="vendaMedia" value={vendaMedia} onChange={(e) => setVendaMedia(e.target.value)} type="number" />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="data">Data</FormLabel>
                        <Input id="data" value={data} onChange={(e) => setData(e.target.value)} type="date" />
                    </FormControl>
                </Stack>
            </CModal>
        </>
    );
}

export default ModalEditarCustodia;