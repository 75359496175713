import { Button, Icon, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React, { ChangeEventHandler, forwardRef, useState } from 'react';
import { KeyboardEventHandler } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

type InputPasswordType = {
    ref?: React.Ref<HTMLInputElement>;
    value?: string
    id?: any
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
    onChange?: ChangeEventHandler<HTMLInputElement>
}

const InputPassword: React.FC<InputPasswordType> = forwardRef((prop, ref: React.Ref<HTMLInputElement>) => {
    const [view, setView] = useState<boolean>(false)
    return (
        <InputGroup size="lg">
            <Input
                ref={ref}
                id={prop.id}
                onKeyDown={prop.onKeyDown}
                value={prop.value}
                onChange={prop.onChange}
                type={view ? "text" : "password"}
                pr="4rem"
            />
            <InputRightElement width="4rem" >
                <Button
                    size="sm"
                    onClick={() => setView(!view)}
                >
                    {view ?
                        <Icon h={6} w={6} as={AiFillEye} color="primary.100" /> :
                        <Icon h={6} w={6} as={AiFillEyeInvisible} color="primary.100" />}
                </Button>
            </InputRightElement>
        </InputGroup>
    );
})

export default InputPassword;