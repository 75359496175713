import { Box, Button, Center, Container, FormControl, FormLabel, Heading, HStack, Icon, Input, Spinner, Stack, Stat, StatLabel, StatNumber, Textarea, useToast } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { MdArrowBack } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import Api from '../../api/Api';
import { RolesContext } from '../../App';
import CModal from '../../components/CModal/Index';
import { Verbose } from '../../service/Verbose';
import { SaldoItem } from '../../types/OperaroesTypes';


type saldoStatePage = { traderId: string, nome: string }
const SaldoTela: React.FC = () => {
  const { state } = useLocation();
  const { traderId, nome } = state ? state as saldoStatePage : { traderId: "", nome: "" };
  const [saldo, setSaldo] = useState<string>("");
  const { roles } = useContext(RolesContext);
  const navigate = useNavigate();
  const [movimentacaoSaldoList, setMovimentacalSaldoItem] = useState<SaldoItem[]>([]);
  const [open, setOpen] = useState<boolean>(false)
  const [valor, setValor] = useState<string>("0")
  const [data, setData] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("")
  const toast = useToast();
  const [load, setLoad] = useState<boolean>(false)
  //Saldo
  const getTotalSaldo = useCallback(() => {
    setSaldo("")
    const isTradeId = (traderId !== "" && traderId !== undefined) ? traderId : "";
    const routeUser = isTradeId === "" ? `Saldo/saldoUsuario` : `Saldo/saldoUsuario/${traderId}`;
    Api.get(routeUser)
      .then(res => {
        Verbose({ [`${routeUser}`]: res.data })
        setSaldo(res.data)
      })
      .catch(err => {
        Verbose({ routeUser: err.response });
      })
  }, [traderId])

  const getMovimentacaoSaldo = useCallback(() => {
    setMovimentacalSaldoItem([])
    const isTradeId = (traderId !== "" && traderId !== undefined) ? traderId : "";
    const routeUser = isTradeId === "" ? `Saldo/movimentacaoSaldoUsuario` : `Saldo/movimentacaoSaldoUsuario/${traderId}`;
    Api.get<SaldoItem[]>(routeUser)
      .then(res => {
        Verbose({ [`${routeUser}(response)`]: res.data })
        setMovimentacalSaldoItem(res.data);
      })
      .catch(err => {
        Verbose({ [`${routeUser}(catch)`]: err.response });
      })
  }, [traderId])

  function criarSaldo() {
    const request = {
      traderId,
      valor,
      dataReferencia: data,
      descricao
    }
    setLoad(true)
    Api.post("/Saldo/Movimentar", request)
      .then(() => {
        setLoad(false)
        setOpen(false)
        toast({
          title: "Saldo criado com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true
        })
        getMovimentacaoSaldo()
        getTotalSaldo()
      })
      .catch(err => {
        Verbose({ "/Saldo/Movimentar(catch)": err.response.data })
        setLoad(false);
      })
  }

  useEffect(() => {
    // if (roles.find(e => e === "ADMINISTRADOR"))
    //   if (traderId !== "" && traderId !== null) {
    //     getTotalSaldo();
    //     getMovimentacaoSaldo();
    //   } else { navigate('/usuarios'); }

    // if (roles.find(e => e === "TRADER") && !roles.find(e => e === "ADMINISTRADOR")) {
    //   getTotalSaldo();
    //   getMovimentacaoSaldo();
    // }

    if (traderId !== "" && traderId !== null) {
      if (roles.find(e => e === "ADMINISTRADOR")) {
        getTotalSaldo();
        getMovimentacaoSaldo();
      } else { navigate('/operacoes') }
    } else {
      getTotalSaldo();
      getMovimentacaoSaldo();
    }

  }, [getTotalSaldo, getMovimentacaoSaldo, roles, navigate, traderId])

  const movimentacaoSaldoColumn: TableColumn<SaldoItem>[] = [
    {
      name: "Descrição",
      selector: (row) => row.descricao,
      sortable: true,
    },
    {
      name: "Data",
      selector: (row) => row.data,
      sortable: true,
    },
    {
      name: "Valor",
      selector: (row) => row.valor,
      sortable: true,
    }
  ]

  return (
    <Container maxW="5xl">
      {
        roles.find(e => e === "ADMINISTRADOR") && (
          <Button
            size="sm"
            leftIcon={<Icon as={MdArrowBack} color="white.100" />}
            mb="6"
            variant="primary"
            onClick={() => navigate("/usuarios")}>
            Voltar
          </Button>
        )
      }
      <Heading size="lg" pr="0" mb="8" mt="8">
        Saldos {nome ? `de ${nome}` : ""}
      </Heading>
      <HStack justifyContent="space-between" w="100%">
        <Stat mb="4">
          <StatLabel>Saldo total</StatLabel>
          <StatNumber>{saldo}</StatNumber>
        </Stat>

        <Button
          onClick={() => setOpen(true)}
          variant="primary"
        >
          Criar saldo
        </Button>
      </HStack>
      <Box>
        <Box bg='white.100' p='8' w="100%" borderRadius="16px" boxShadow="0px  8px 28px #00000006" >
          <DataTable
            columns={movimentacaoSaldoColumn}
            data={movimentacaoSaldoList}
            noDataComponent={
              <Center p="4">
                Nenhuma movimentação encontrada.
              </Center>
            }
            fixedHeader
            fixedHeaderScrollHeight='50vh'
            progressComponent={
              <Center p="20">
                <Spinner
                  w="80px"
                  h="80px"
                  color="primary.100"
                  thickness='8px'
                />
              </Center>
            }
          />
        </Box>
      </Box>
      {/* MODAL CRIAR SALDO */}
      <CModal
        title="Criar saldo"
        footer={
          <HStack spacing={4} pt="4">
            <Button onClick={() => setOpen(false)}>
              Cancelar
            </Button>
            <Button isLoading={load} onClick={() => criarSaldo()} variant="primary">
              Criar
            </Button>
          </HStack>
        }
        onClose={() => setOpen(false)}
        open={open}
      >
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>Valor</FormLabel>
            <Input
              value={valor}
              onChange={(e) => setValor(e.target.value)}
              type="number"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Data</FormLabel>
            <Input
              value={data}
              onChange={(e) => setData(e.target.value)}
              type="date"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Descição</FormLabel>
            <Textarea
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              rows={6}
            />
          </FormControl>
        </Stack>
      </CModal>
    </Container>
  );
}

export default SaldoTela;