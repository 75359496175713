import React, { useState } from "react";
import {
  Heading,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Button,
  Icon,
  HStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import PerfilForm from "../../components/PerfilForm/Index";
import TraderForm from "../../components/PerfilForm/TraderForm";
import IndicadorForm from "../../components/PerfilForm/IndicadorForm";
import { roles } from "../../components/ProtectRouteSystem";
import CModal from "../../components/CModal/Index";
import Api from "../../api/Api";
import { Verbose } from "../../service/Verbose";

interface stateId { id?: string, nome?: string, roles: roles[] }

const PerfilAdmin: React.FC = () => {
  //Capaz de alterar os dados
  //Dados do cliente
  const { state } = useLocation();
  const { id, nome, roles } = state ? state as stateId : { id: null, nome: null, roles: null };
  const navigate = useNavigate();
  const [openTornarAdm, setOpenTornarAdm] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)
  const toast = useToast();
  function tornarAdmin() {
    setLoad(true)
    Api.get(`perfilAdministrador/tornarAdministrador/${id}`)
      .then(() => {
        setLoad(false)
        setOpenTornarAdm(false)
        navigate("/usuarios")
        toast({
          title: "Cliente se tornou um(a) administrador(a)",
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      })
      .catch(err => {
        Verbose({ [`perfilAdmin/tornarAdministrador/${id}`]: err.response.data })
        setLoad(false)
      })
  }


  return (
    <>
      <Container maxW={"container.md"}>
        <HStack spacing={4} mb="6" >
          <Button
            size="sm"
            leftIcon={<Icon as={MdArrowBack} color="white.100" />}
            variant="primary"
            onClick={() => navigate("/usuarios")}>
            Voltar
          </Button>
          {
            !roles?.find(e => e === "ADMINISTRADOR") && (
              <Button variant="primary-outline" size="sm" onClick={() => setOpenTornarAdm(true)}>
                Tornar usuário administrador
              </Button>
            )
          }
        </HStack>

        <Heading as="h2" mb="6">
          Perfil de {nome}
        </Heading>

        <Tabs
          isFitted
        >
          <TabList>
            <Tab _selected={{ color: "primary.100", borderBottomColor: "primary.100" }}>Dados do usuário</Tab>
            <Tab _selected={{ color: "primary.100", borderBottomColor: "primary.100" }} >Dados de trader</Tab>
            <Tab _selected={{ color: "primary.100", borderBottomColor: "primary.100" }} >Dados de indicação</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <PerfilForm
                editavel
                isAdministrador
                idUsuario={id}
              />
            </TabPanel>
            <TabPanel>
              {/* <TabTrader idUsuario={usuarioPerfil?.idUsuario!} /> */}
              <TraderForm
                idUsuario={id}
                isAdministrador
              />
            </TabPanel>
            <TabPanel>
              <IndicadorForm
                isAdministrador
                idUsuario={id}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
      <CModal
        open={openTornarAdm}
        onClose={() => setOpenTornarAdm(false)}
        title="Tornar usuário administrador"
        footer={
          <HStack spacing={4}>
            <Button onClick={() => setOpenTornarAdm(false)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={() => tornarAdmin()} isLoading={load}  >
              Tornar administrador(a)
            </Button>
          </HStack>
        }
      >
        <>
          <Text>Você deseja que esse usuário seja um administrador?</Text>
        </>
      </CModal>
    </>
  );
};

export default PerfilAdmin;
