import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.timeout = 180000;

const Api = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "https://localhost:7048/api/" : "/api/",
})

const token = localStorage.getItem("@CI_TOKEN");
if (token) Api.defaults.headers.common["Authorization"] = token;

export default Api;

