import { Box, Center, Spinner, Text, Heading, Container, Flex, Button, Icon, HStack, Input } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BiExpand } from 'react-icons/bi';
import { FiMinimize } from 'react-icons/fi';
import Api from '../../api/Api';
import { Verbose } from '../../service/Verbose';
import { historicoImportacao } from '../../types/ImportacaoTypes';
import InputMask from 'react-input-mask';
const HistoricoMovimentacao: React.FC = () => {
    const [load, setLoad] = useState<boolean>(false);
    const [itens, setItens] = useState<historicoImportacao[]>([])
    const [expand, setExpand] = useState<boolean>(false);
    const [data, setData] = useState<string>(moment(new Date()).format("DD/MM/YYYY"));
    const columns: TableColumn<historicoImportacao>[] = [
        {
            name: "Data",
            selector: (row) => row.data,
            cell: (row) => (
                <Text>{moment(row.data).format("DD/MM/YYYY")}</Text>
            ),
            width: "80px",
            compact: true,
            sortable: true
        },
        {
            name: "SubConta",
            selector: (row) => row.subconta,
            compact: true,
            width: "80px",
            sortable: true,
        },
        {
            name: "Ativo",
            compact: true,
            selector: (row) => row.ativo,
            sortable: true,
        },
        {
            name: "Resultado (%)",
            selector: (row) => row.resultadoPorcentagem,
            compact: true,
            width: "120px",
            sortable: true,
        },
        {
            name: "Resultado",
            compact: true,
            selector: (row) => row.resultado,
            sortable: true,
            width: '64px',
        },
        {
            name: "Qtd Liquida",
            compact: true,
            selector: (row) => row.qtdLiquida,
            sortable: true,
        },
        {
            compact: true,
            name: "Qtd Compra",
            selector: (row) => row.qtdCompra,
            sortable: true,
        },
        {
            name: "Qtd Venda",
            selector: (row) => row.qtdVenda,
            compact: true,
            sortable: true,
        },
        {
            name: "Compra Média",
            compact: true,
            selector: (row) => row.compraMedia,
            sortable: true,
        },
        {
            name: "Venda Média",
            compact: true,
            selector: (row) => row.vendaMedia,
            sortable: true,
        },
        {
            name: "Total compra",
            compact: true,
            selector: (row) => row.totalCompra,
            sortable: true,
        },
        {
            name: "Total Venda",
            compact: true,
            selector: (row) => row.totalVenda,
            sortable: true,
        }
    ]

    useEffect(() => {
        const regData = new RegExp(/^\d{2}[/]\d{2}[/]\d{4}$/g);
        const verifyData = regData.test(data);
        if (verifyData) {
            setLoad(true);
            Verbose([`/ImportacaoCsv/historico?Date=${data}(request)`]);
            Api.get<historicoImportacao[]>(`/ImportacaoCsv/historico?Date=${data}`)
                .then(res => {
                    Verbose({ [`/ImportacaoCsv/historico?Date=${data}`]: res.data });
                    setItens(res.data)
                    setLoad(false);
                })
                .catch(err => {
                    Verbose({ [`/ImportacaoCsv/historico?Date=${data}(catch)`]: err.response });
                })
        }
    }, [data])


    return (
        <Container
            maxW={expand ? "none" : "5xl"}
            px="0"
        >
            <Flex justifyContent="space-between" alignItems="center">
                <Heading size="lg" pr="4" mb="4">
                    Histórico de Importação
                </Heading>
                <HStack spacing={4}>
                    <Input
                        as={InputMask}
                        mask="99/99/9999"
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                        placeholder="Filtrar por data"
                    />
                    <Button
                        variant="primary-outline"
                        onClick={() => setExpand(!expand)}
                    >
                        <Icon as={expand ? FiMinimize : BiExpand} />
                    </Button>
                </HStack>
            </Flex>
            <Box
                bg='white.100'
                p='8'
                borderRadius={expand ? "0px" : "16px"}
                boxShadow="0px  8px 28px #00000006"
            >
                <Box>
                    <DataTable
                        columns={columns}
                        data={itens}
                        fixedHeaderScrollHeight="600px"
                        fixedHeader
                        customStyles={{
                            cells: {
                                style: {
                                    fontSize: "10px",
                                    fontWeight: "bold"
                                }
                            }
                        }}
                        highlightOnHover
                        progressPending={load}
                        // pagination
                        paginationComponentOptions={{
                            rowsPerPageText: "Importações por página",
                            rangeSeparatorText: "de"
                        }}
                        noDataComponent={
                            <Center p="8">
                                <Text>Nenhum Histórico encontrado.</Text>
                            </Center>
                        }
                        progressComponent={
                            <Center p="20">
                                <Spinner
                                    w="80px"
                                    h="80px"
                                    color="primary.100"
                                    thickness='8px'
                                />
                            </Center>
                        }
                    />
                </Box>
            </Box>
        </Container>
    );
}

export default HistoricoMovimentacao;